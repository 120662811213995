import Repository from './Repository.js';

class ActualitiesRepository extends Repository {
    async getActualities(query) {
        return await this.get('actualities', query);
    }

    async createActuality(body) {
        return await this.post('actualities', body);
    }

    async updateActuality(actualityId, body) {
        return await this.post(`actualities/${actualityId}`, body);
    }

    async deleteActuality(actualityId) {
        return await this.delete(`actualities/${actualityId}`);
    }

    async removeAttachment(attachmentId, body) {
        return await this.delete(`actualities/attachments/${attachmentId}`, body);
    }

    async updateAttachment(attachmentId, body) {
        return await this.put(`actualities/attachments/${attachmentId}`, body);
    }

    async uploadAttachment(actualityId, input) {
        const body = new FormData();

        for (const key in input) {
            const value = input[key];
            if (Array.isArray(value)) {
                for (const val of value) {
                    body.append(key, val);
                }
            } else {
                body.append(key, value);
            }
        }

        return await this.post(`actualities/${actualityId}/attachments`, body, false);
    }
}

export default new ActualitiesRepository();
