import Repository from './Repository.js';

class ProductsRepository extends Repository {
    async getProducts(query) {
        return await this.get('products', query);
    }

    async getProduct(productId, query) {
        return await this.get(`products/${productId}`, query);
    }

    async getProductsSummary(query) {
        return await this.get('products/summary', query);
    }
}

export default new ProductsRepository();
