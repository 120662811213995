import Vue from 'vue';

import { extend, setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required, email, max, confirmed } from 'vee-validate/dist/rules';

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

setInteractionMode('aggressive');

extend('required', {
    ...required,
    message: 'Ce champ est obligatoire'
});

extend('email', {
    ...email,
    message: 'Vous devez saisir une adresse email valide'
});

extend('max', {
    ...max,
    message: 'Vous avez atteint le nombre maximum de caractères'
});

extend('number', {
    validate: (value) => /^-?[\d ]*[.|,]?(\d+)?$/.test(value),
    message: 'Vous devez saisir un nombre'
});

extend('minValue', {
    validate(value, { minValue }) {
        if (typeof value === 'string') {
            value = value.replace(',', '.');
            value = value.replace(' ', '');
        }
        if (isNaN(value)) {
            return false;
        } else {
            return parseFloat(value) >= parseFloat(minValue);
        }
    },
    params: ['minValue'],
    message: 'La valeur ne pas pas être inférieur à {minValue}'
});

extend('maxValue', {
    validate(value, { maxValue }) {
        if (typeof value === 'string') {
            value = value.replace(',', '.');
            value = value.replace(' ', '');
        }
        if (isNaN(value)) {
            return false;
        } else {
            return parseFloat(value) <= parseFloat(maxValue);
        }
    },
    params: ['maxValue'],
    message: 'La valeur ne pas pas être supérieur à {maxValue}'
});

extend('confirmed', {
    ...confirmed,
    message: 'Les mots de passe ne sont pas identiques'
});

extend('phone', {
    validate(value) {
        if (typeof value !== 'string') {
            return false;
        }

        const regex = /^(\d|\+|\(|\)| )+$/;
        return regex.test(value);
    },
    message: 'Vous devez saisir un numéro valide'
});

extend('document', {
    params: ['allowedTypes'],
    validate(file, { allowedTypes }) {
        allowedTypes = allowedTypes.split(',');
        return file && file.type && allowedTypes.includes(file.type);
    },
    message: 'Vous devez choisir un document valide'
});