<template>
    <v-dialog v-model="isOpen" width="650px" max-width="95%">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Visite guidée
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text class="pt-4 px-4">
                <template v-if="$route.path === '/'">
                    <h1> L'entête </h1> <br>
                    <p>
                        L'en-tête, présent sur toutes les pages de l'espace, permet de facilement retourner sur la page d'accueil de l'espace, de naviguer entre les différentes rubriques de l'espace, et d'accéder à des fonctionnalités de gestion dont, par exemple, le changement de votre mot de passe et la déconnexion de votre espace.
                    </p>

                    <h2> Logo </h2> <br>
                    <p>
                        Un clic sur le logo vous permet de retourner sur la page d'accueil de votre espace. Sur un petit écran, par exemple sur un téléphone mobile, le logo est masqué (pour retourner à la page d'accueil, ouvrez la barre de navigation en cliquant sur l'icône carrée en haut à gauche et cliquez sur "Accueil").
                    </p>

                    <h2> Barre de navigation </h2> <br>
                    <p>
                        La barre de navigation principale permet d'accéder aux différentes rubriques de l'espace. Sur un petit écran, par exemple sur un téléphone mobile, les liens sont accessibles à partir de l'icône carrée en haut à gauche.
                    </p>
                    <ul>
                        <li>
                            Programmes
                            <ul>
                                <li>
                                    Listing de tous les programmes disponibles dans l'espace (affichage possible en mode liste et sur une carte), avec possibilité d'effectuer des recherches par exemple par localisation géographique et/ou par disponibilités
                                </li>
                            </ul>
                        </li>

                        <li>
                            Lots
                            <ul>
                                <li>
                                    Listing de tous les lots proposés dans votre espace, avec possibilité d'effectuer des recherches par exemple par localisation géographique et/ou typologie ou budget
                                </li>
                            </ul>
                        </li>

                        <li>
                            Contacts
                            <ul>
                                <li>
                                    Listing de tous vos contacts, avec accès notamment à leurs coordonnées et à tout l'historique des activités
                                    <span v-if="getConfig('sales.enabled', false)">(toutes les demandes de dénonciation, l'historique des options et des réservations)</span>
                                    <span v-else>(toutes les demandes de dénonciation et l'historique des options)</span>
                                </li>
                            </ul>
                        </li>

                        <li>
                            Options
                            <ul>
                                <li>
                                    Listing de tout l'historique des options posées dans votre espace partenaires
                                </li>
                            </ul>
                        </li>

                        <li v-if="getConfig('sales.enabled', false)">
                            Réservations
                            <ul>
                                <li>
                                    Listing de tout l'historique des réservations réalisées dans votre espace partenaires et possibilité d'accéder aux détails de ces réservations (suivi et modification de l'avancement des projets d'acquisition, dont par exemple les dates clés et les documents contractuels)
                                </li>
                            </ul>
                        </li>

                        <li>
                            Messages
                            <ul>
                                <li>
                                    Messagerie vous permettant de contacter un interlocuteur du promoteur et de consulter tous les précédents échanges
                                </li>
                            </ul>
                        </li>
                    </ul> <br>

                    <h2> Mon compte </h2> <br>
                    <p>
                        Un clic sur le nom associé à votre compte permet d'accéder aux fonctionnalités suivantes :
                    </p>
                    <ul>
                        <li>
                            Mon compte
                            <ul>
                                <li> Changement de votre mot de passe </li>
                            </ul>
                        </li>

                        <li>
                            Calculette financière
                            <ul>
                                <li>
                                    Outil de calcul permettant d'estimer :
                                    <ul>
                                        <li> Les mensualités pour un capital emprunté (saisissez le montant emprunté, le taux d'intérêt annuel, la durée en années, et validez en appuyant sur "Calculer") </li>
                                        <li> La capacité d'emprunt à partir des mensualités envisagées (saisissez le montant des mensualités, le taux d'intérêt annuel, la durée en années, et validez en appuyant sur "Calculer") </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li>
                            Visite guidée
                            <ul>
                                <li> "Aide" : Présentation des fonctionnalités de la page </li>
                            </ul>
                        </li>

                        <li>
                            Déconnexion
                            <ul>
                                <li> Déconnexion de votre espace partenaires (retour sur la page de connexion) </li>
                            </ul>
                        </li>
                    </ul> <br>

                    <h2> Diaporama de programmes </h2> <br>
                    <p>
                        Le diaporama de programmes représente une sélection de programmes de votre espace. Les flèches "précédent" et "suivant" superposées sur le visuel vous permettent de consulter toute la sélection.
                    </p>
                    <p>
                        Un clic sur le nom ou la localisation géographique du programme dans la partie haute de la zone permet d'accéder à la fiche détaillée du programme, comprenant notamment plus de détails sur les caractéristiques du programme et sa grille de prix.
                    </p>

                    <h2> Moteur de recherche </h2> <br>
                    <p>
                        Le moteur de recherche superposé sur le diaporama de programmes permet d'accéder à la liste complète de toutes les disponibilités de tous les programmes accessibles dans votre espace, filtrée sur les critères de votre choix, dont notamment la localisation ou la typologie.
                    </p>
                    <p>
                        La recherche par localisation permet de retrouver les programmes par région, département ou commune : En cliquant sur une région ou un département, vous pouvez accéder à un niveau plus fin.
                    </p>
                    <p>
                        Les recherches par typologie et tout autre champ listant une présélection d'options permettent de choisir une ou plusieurs options à la fois (un premier clic sur une option la sélectionne, un deuxième clic la désélectionne).
                    </p>
                    <p>
                        La recherche par budget permet de saisir, en euros, le prix de vente maximum souhaité.
                    </p>
                    <p>
                        Les résultats sont affichés sur la liste des lots lorsque la recherche est lancée à l'aide du bouton "Rechercher".
                    </p>

                    <h2> Accès rapide </h2> <br>
                    <p>
                        Le menu déroulant "Accès rapide" permet de parcourir tous les programmes disponibles pour accéder directement aux fiches détaillées. Vous pouvez rechercher un programme donné par son nom, en saisissant une partie du nom du programme dans le champ.
                    </p>

                    <h2> Actualités</h2> <br>
                    <p>
                        La zone "Actualités" permet de consulter toute actualité à la une, promotion ou autre communication que le promoteur souhaite partager avec vous. Dans le cas où une actualité contient plusieurs visuels, ils peuvent être consultés un par un en cliquant sur les flèches superposées sur le premier visuel. Dans le cas où plusieurs actualités ont été publiées, elles peuvent être consultées une par une à partir des flèches ou des icônes rondes accessibles sous le texte.
                    </p>
                    <p>
                        A l'arrivée sur la page, seul un extrait du début du texte est affiché. Le lien "Voir plus" sous cet extrait vous permet de consulter les visuels en plus grande taille et de lire le texte complet.
                    </p>

                    <h2> Mes derniers contacts </h2> <br>
                    <p>
                        Dans la zone "Contacts" vous visualisez vos dernières demandes de dénonciation.
                    </p>
                    <p>
                        En cliquant sur un contact, vous accédez à sa fiche permettant notamment de consulter ses coordonnées et tout l'historique de ses
                        <span v-if="getConfig('sales.enabled', false)">dénonciations, options et réservations.</span>
                        <span v-else>dénonciations et options.</span>
                    </p>
                    <p>
                        Un clic sur l'icône "Loupe" en haut de la zone ouvre une zone de saisie permettant de rechercher un contact par son nom, adresse e-mail ou numéro de téléphone. Lorsque la recherche est lancée, vous êtes redirigé(e) sur la liste de tous les contacts correspondant à votre recherche.
                    </p>
                    <p>
                        Le lien "Voir plus" en bas de la zone pointe sur la liste de l'ensemble de vos contacts.
                    </p>

                    <h2> Mes dernières options </h2> <br>
                    <p>
                        Dans la zone "Options" vous visualisez les dernières options posées à partir de votre espace et un résumé des données associées (notamment le contact, la référence et la typologie du lot ainsi que le nom du programme).
                    </p>
                    <p>
                        En cliquant sur le nom d'un contact, vous accédez à sa fiche permettant notamment de consulter ses coordonnées et tout l'historique des
                        <span v-if="getConfig('sales.enabled', false)">dénonciations, options et réservations.</span>
                        <span v-else>dénonciations et options.</span>
                    </p>
                    <p>
                        Un clic sur l'icône "Loupe" en haut de la zone ouvre une zone de saisie permettant de rechercher une option par le nom, adresse e-mail ou numéro de téléphone du contact associé, par la référence du lot ou par le nom du programme. Lorsque la recherche est lancée, vous êtes redirigé(e) sur la liste de toutes les options correspondant à votre recherche.
                    </p>
                    <p>
                        Le lien "Voir plus" en bas de la zone pointe sur la liste de l'ensemble des options posées à partir de votre espace.
                    </p>

                    <div v-if="getConfig('sales.enabled', false)">
                        <h2> Mes dernières réservations </h2> <br>
                        <p>
                            Dans la zone "Réservations" vous visualisez les dernières réservations réalisées à partir de votre espace et un résumé des données associées (notamment le contact, la référence et la typologie du lot ainsi que le nom du programme).
                        </p>
                        <p>
                            En cliquant sur une réservation, vous accédez à la fiche réservation permettant de suivre l'avancement du projet d'acquisition.
                        </p>
                        <p>
                            Un clic sur l'icône "Loupe" en haut de la zone ouvre une zone de saisie permettant de rechercher une réservation par le nom, adresse e-mail ou numéro de téléphone du contact associé, par la référence du lot ou par le nom du programme. Lorsque la recherche est lancée, vous êtes redirigé(e) sur la liste de toutes les réservations correspondant à votre recherche.
                        </p>
                        <p>
                            Le lien "Voir plus" en bas de la zone pointe sur la liste de l'ensemble des réservations réalisées à partir de votre espace.
                        </p>
                    </div>
                </template>

                <template v-if="$route.path === '/programmes'">
                    <p>
                        Cette page permet de consulter l'ensemble des programmes accessibles dans votre espace.
                    </p>
                    <p>
                        Par défaut, sur un ordinateur desktop, la page est présentée en deux parties : Mode listing d'un côté et présentation des programmes sur une carte de l'autre. Un clic sur l'icône carrée "Voir les programmes en liste" permet de masquer la carte et de naviguer plus facilement en mode listing. L'icône "Voir les programmes sur une carte" permet de revenir sur la présentation initiale en deux parties, listing et carte.
                    </p>
                    <p>
                        Sur un appareil mobile, la page consiste en un listing de programmes. L'icône "Voir les programmes sur une carte" permet d'afficher les programmes sur une carte interactive, et l'icône carrée "Voir les programmes en liste" permet de revenir au mode listing initial.
                    </p>

                    <h2> Outils de navigation </h2> <br>
                    <p>
                        En haut de page, deux outils de navigation sont mis à disposition pour faciliter la consultation des programmes :
                    </p>
                    <ul>
                        <li>
                            Tri par différents critères, par exemple le nom du programme ou la ville. Possibilité de choisir ordre croissant ou décroissant en cliquant sur l'icône à côté de la zone
                        </li>
                        <li>
                            Pagination : Dans le cas où la liste contient un grand nombre de programmes, la pagination permet de passer d'une page à l'autre (première, dernière, précédente, suivante, ou en cliquant directement sur un numéro de page)
                        </li>
                    </ul> <br>

                    <h2> Recherche d'un programme </h2> <br>
                    <p>
                        En haut de page, deux outils de recherche sont mis à disposition pour faciliter les recherches par différents critères.
                    </p>
                    <ul>
                        <li>
                            Recherche rapide par champ de saisie libre : Permet d'accéder aux programmes par leur nom
                        </li>
                        <li>
                            Zone "+ de filtres" : Un clic sur le lien "+ de filtres" ouvre une zone contenant plusieurs critères de recherche différents, notamment :
                            <ul>
                                <li>
                                    Localisation et typologie des disponibilités (fonctionnement identique au champ similaire du moteur de recherche de la page d'accueil)
                                </li>
                                <li>
                                    Budget (curseur de plage permettant de sélectionner un prix de vente entre deux valeurs, minimum et maximum)
                                </li>
                            </ul>
                            Les résultats sont affichés lorsque la recherche est lancée à l'aide du bouton "Rechercher". La zone "+ de filtres" peut de nouveau être masquée en cliquant sur le bouton désormais appelé "- de filtres"
                        </li>
                    </ul> <br>

                    <h2> Listing </h2> <br>
                    <p>
                        Chaque bloc "Programme" contient un aperçu synthétique sur les données relatives au programme, notamment le nom, la localisation, la date de livraison et une vue sur les disponibilités. En cliquant sur un bloc, vous êtes pointé(e) sur la fiche détaillée du programme.
                    </p>
                    <p>
                        Un bandeau "A la une" sur un programme indique que la fiche programme contient une alerte "flash" publiée par le promoteur, par exemple une promotion concernant les disponibilités du programme, avertissement au sujet des derniers lots disponibles à la fin de la commercialisation, ou une actualité sur l'avancement des travaux.
                    </p>

                    <h2> Carte </h2> <br>
                    <p>
                        Chaque programme de la liste courante représente un pointeur sur la carte. Lorsqu'une recherche est lancée ou lorsque la pagination est utilisée, la carte est mise à jour de la même manière que le listing de programmes de la page.
                    </p>
                    <p>
                        La carte peut être zoomée et déplacée. Lorsque la zone géographique est limitée par exemple à la suite d'une recherche par localisation géographique, au lieu de représenter toute la France, la carte est automatiquement zoomée sur une région moins vaste.
                    </p>
                    <p>
                        En cliquant sur un pointeur, une infobulle contenant quelques caractéristiques sur le programme (notamment un visuel et un aperçu sur les disponibilités) est affichée. En cliquant sur le nom du programme au sein de l'infobulle, vous êtes pointé(e) sur la fiche détaillée du programme.
                    </p>
                </template>

                <template v-if="$route.path.includes('/programmes/')">
                    <p>
                        Les fiches programme permettent de consulter un grand nombre de détails sur un programme, y compris toute la documentation disponible et sa grille de prix.
                    </p>
                    <p>
                        Les informations détaillées comprennent notamment :
                    </p>

                    <ul>
                        <li> Le nom, la référence et l'adresse du programme </li>
                        <li> Informations sur la fiscalité, taux TVA, taux de rémunération </li>
                        <li> Un texte descriptif </li>
                        <li> Date prévisionnelle de livraison </li>
                    </ul> <br>

                    <h2> Visuels du programme </h2> <br>
                    <p>
                        L'ensemble des visuels disponibles pour un programme sont accessibles à partir des flèches précédent / suivant superposées sur le premier visuel affiché à l'arrivée sur la page.
                    </p>
                    <p>
                        Dans le cas où vous cliquez sur le pictogramme "carte" en haut de page à proximité du nom du programme, nommé "Voir le programme sur une carte", le diaporama des visuels est remplacé par une carte interactive. Pour revenir sur la consultation des visuels, appuyez sur le pictogramme carré nommé "Voir les photos du programme" à côté du pictogramme "carte".
                    </p>

                    <h2> A la une </h2> <br>
                    <p>
                        Toute actualité "A la une" éventuellement publiée par le promoteur au sujet du programme s'affiche superposée sur les visuels. Il peut s'agir d'une alerte "flash" telle qu'une promotion concernant les disponibilités du programme, avertissement au sujet des derniers lots disponibles à la fin de la commercialisation, ou une actualité sur l'avancement des travaux.
                    </p>

                    <h2> Consultation du programme sur une carte </h2> <br>
                    <p>
                        Le pictogramme "carte" en haut de page à proximité du nom du programme, nommé "Voir le programme sur une carte", permet d'accéder à une carte interactive comprenant un pointeur placé à l'emplacement du programme. La carte peut être zoomée et déplacée.
                    </p>
                    <p>
                        Dans le cas où vous cliquez sur le pictogramme carré nommé "Voir les photos du programme" à côté du pictogramme "carte", la zone est remplacée par le diaporama des visuels du programme (affiché par défaut à l'arrivée sur la page). Pour revenir sur la carte, appuyez de nouveau sur le pictogramme "carte".
                    </p>

                    <h2> Documentation </h2> <br>
                    <p>
                        Les documents liés au programme sont consultables dans la zone "Documents". Ils sont regroupés par catégorie : Chacune des catégorie est cliquable permettant d'accéder aux documents relatifs (un premier clic ouvrant la catégorie, un deuxième clic permettant de la masquer). Les documents sont présentés dans l'ordre alphabétique et peuvent être consultés et téléchargés à partir de l'icône "pdf" précédent le titre du document.
                    </p>

                    <h2> Zone promotionnelle / Actualités </h2> <br>
                    <p>
                        La zone promotionnelle permet de consulter toute actualité, promotion ou autre communication que le promoteur souhaite partager avec vous, concernant le programme. Dans le cas où une actualité contient plusieurs visuels, ils peuvent être consultés un par un en cliquant sur les flèches superposées sur le premier visuel. Dans le cas où plusieurs actualités ont été publiées, elles peuvent être consultées une par une à partir les flèches ou les icônes rondes accessibles sous le texte.
                    </p>
                    <p>
                        A l'arrivée sur la page, seul un extrait du texte est affiché. Le lien "Voir plus" sous cet extrait vous permet de consulter les visuels en plus grande taille et de lire le texte complet.
                    </p>

                    <h2> Grille de prix : Onglet "Lots" </h2> <br>
                    <p>
                        Sous l'onglet "Lots", ouvert par défaut à l'arrivée sur la page, vous visualisez la grille de prix du programme.
                    </p>
                    <p>
                        La présentation et le fonctionnement de cet onglet sont identiques à la rubrique principale "Lots", à l'exception des informations relatives au programme, la grille de la fiche programme étant automatiquement filtrée sur les lots du programme.
                    </p>

                    <h2> Activités </h2> <br>
                    <h3> Onglet "Contacts" </h3> <br>
                    <p>
                        En appuyant sur l'onglet "Contacts", vous visualisez vos contacts, filtrés sur ceux liés au programme ou à un des lots du programme.
                    </p>
                    <p>
                        La présentation et le fonctionnement de cet onglet sont identiques à la rubrique principale "Contacts", à l'exception des informations relatives au programme, le listing de la fiche programme étant automatiquement filtré sur les contacts relatifs au programme.
                    </p>

                    <h3> Onglet "Options" </h3> <br>
                    <p>
                        En appuyant sur l'onglet "Options", vous visualisez l'historique des options posées à partir de votre espace, filtré sur celles liées à un des lots du programme.
                    </p>
                    <p>
                        La présentation et le fonctionnement de cet onglet sont identiques à la rubrique principale "Options", à l'exception des informations relatives au programme, le listing de la fiche programme étant automatiquement filtré sur les options relatives au programme.
                    </p>

                    <div v-if="getConfig('sales.enabled', false)">
                        <h3> Onglet "Réservations" </h3> <br>
                        <p>
                            En appuyant sur l'onglet "Réservations", vous visualisez l'historique des réservations réalisées à partir de votre espace, filtré sur celles liées à un des lots du programme.
                        </p>
                        <p>
                            La présentation et le fonctionnement de cet onglet sont identiques à la rubrique principale "Réservations", mais le listing est automatiquement filtré sur les réservations relatives au programme.
                        </p>
                    </div>

                    <h2> Actions </h2> <br>
                    <p>
                        Les actions disponibles sur la fiche programme sont :
                    </p>
                    <ul>
                        <li>
                            Dénonciation d'un contact (bouton en haut de page, bouton en haut de l'onglet "Contacts", ou l'icône présent au niveau de chaque contact existant de liste des contacts)
                        </li>
                        <li>
                            Actions de la grille de prix, comme dans la rubrique principale "Lots" (poser une option, confirmer une option,
                            <span v-if="getConfig('sales.enabled', false)">réaliser une réservation, </span>annuler une option)
                        </li>
                        <li>
                            Actions du listing des contacts, comme dans la rubrique principale "Contacts" (dénoncer un nouveau contact ou dénoncer de nouveau un contact existant pour le programme en question, optionner un lot du programme)
                        </li>
                        <li>
                            Actions du listing des options, comme dans la rubrique principale "Options" (optionner un lot du programme, confirmer une option,
                            <span v-if="getConfig('sales.enabled', false)">réaliser une réservation, </span>annuler une option)
                        </li>
                        <li v-if="getConfig('sales.enabled', false)">
                            Accès sur les fiches réservations du programme à partir de l'onglet "Réservations"
                        </li>
                    </ul>
                </template>

                <template v-if="$route.path === '/lots'">
                    <p>
                        Cette page représente la liste complète des disponibilités de tous les programmes confondus. Elle est accessible par le menu principal et à partir d'une recherche effectuée sur la page d'accueil.
                    </p>
                    <p>
                        Le listing contient les lots en stock et les lots sous option (en cours ou confirmée). Les lots réservés et vendus sont retirés des disponibilités et ne sont plus affichés sur le listing des lots.
                        <span v-if="getConfig('sales.enabled', false)">Vos propres réservations et vos ventes finalisées sont consultables à partir de la rubrique "Réservations".</span>
                    </p>
                    <p>
                        Une grille de prix similaire au listing de cette page est également disponible sur les fiches détaillées des programmes, filtrée automatiquement sur les lots du programme en question.
                    </p>

                    <h2> Outils de navigation </h2> <br>
                    <p>
                        En haut de page, deux outils de navigation sont mis à disposition pour faciliter la consultation des lots :
                    </p>

                    <ul>
                        <li>
                            Tri par différents critères, par exemple le nom du programme, la typologie ou le prix de vente. Possibilité de choisir ordre croissant ou décroissant en cliquant sur l'icône à côté de la zone
                        </li>
                        <li>
                            Pagination : Dans le cas où la liste contient un grand nombre de lots, la pagination permet de passer d'une page à l'autre (première, dernière, précédente, suivante, ou en cliquant directement sur un numéro de page)
                        </li>
                    </ul> <br>

                    <h2> Recherche des lots </h2> <br>
                    <p>
                        En haut de page, deux outils de recherche sont mis à disposition pour faciliter la recherche par différents critères.
                    </p>
                    <ul>
                        <li>
                            Recherche rapide par champ de saisie libre : Permet d'accéder aux lots par leur référence ou par le nom du programme
                        </li>

                        <li>
                            Zone "+ de filtres" : Un clic sur le lien "+ de filtres" ouvre une zone contenant plusieurs critères de recherche différents, notamment :
                            <ul>
                                <li>
                                    Localisation et typologie (fonctionnement identique au champ similaire du moteur de recherche de la page d'accueil)
                                </li>
                                <li>
                                    Budget (curseur de plage permettant de sélectionner un prix de vente entre deux valeurs, minimum et maximum)
                                </li>
                                <li>
                                    Statut commercial des lots (stock, sous option)
                                </li>
                                <li>
                                    Vos propres activités réalisées : Option posée sur un lot à partir de votre espace, ou "aucune", permettant de retrouver les lots sur lesquels vous n'avez effectué aucune action
                                </li>
                                <li>
                                    Statut de vos propres options, permettant d'accéder par exemple à tous les lots pour lesquels une option posée à partir de votre espace a été confirmée ou annulée
                                </li>
                            </ul>
                            Les résultats sont affichés lorsque la recherche est lancée à l'aide du bouton "Rechercher". La zone "+ de filtres" peut de nouveau être masquée en cliquant sur le bouton désormais appelé "- de filtres"
                        </li>
                    </ul> <br>

                    <h2> Listing </h2> <br>
                    <p>
                        La grille de prix permet de consulter les éléments centraux des lots, notamment :
                    </p>
                    <ul>
                        <li>
                            La référence et le statut commercial
                        </li>
                        <li>
                            Plan de vente (accessible par le pictogramme "pdf" devant la nature du lot)
                        </li>
                        <li>
                            Typologie
                        </li>
                        <li>
                            Les surfaces annexes
                        </li>
                        <li>
                            Les lots secondaires associés, par exemple les parkings, illustrés par des petites icônes sous le pictogramme correspondant à la nature du lot principal
                        </li>
                        <li>
                            Informations financières dont le prix de vente : En survolant le prix avec la souris (ordinateurs desktop) ou en y appuyant (appareils mobiles), vous pouvez consulter les détails du prix de vente : Le prix du lot principal suivi par les prix des lots secondaires associés
                        </li>
                        <li>
                            Le nom du programme (cliquable, permettant d'accéder à la fiche programme) et la ville
                        </li>
                    </ul> <br>

                    <h2> Activités réalisées sur les lots </h2> <br>
                    <p>
                        La colonne "Activité" contient la date et l'heure de la prochaine disponibilité pour les lots optionnés en dehors de votre propre espace, et centralise vos propres actions sur les lots, par exemple l'état de la dernière
                        <span v-if="getConfig('sales.enabled', false)">option posée ou la dernière réservation réalisée sur ce lot.</span>
                        <span v-else>option posée.</span>
                    </p>
                    <p>
                        Dans le cas où vous avez effectué plusieurs actions de même type sur un même lot, vous visualisez en priorité l'état de l'action la plus récente, suivi par la mention "+ X" vous informant qu'il existe également d'autres actions du même type, plus anciennes, sur ce lot. Les détails de ces actions peuvent être consultés dans les rubriques respectives.
                    </p>

                    <h2> Actions </h2> <br>
                    <p>
                        La colonne "Actions" permet d'effectuer les actions suivantes lorsque l'état actuel des lots le permet :
                    </p>
                    <ul>
                        <li> Pose d'option pour les lots en stock </li>
                        <li> Confirmation de vos propres options en cours </li>
                        <li> Annulation de vos propres options en cours ou confirmées </li>
                        <li v-if="getConfig('sales.enabled', false)"> Réalisation d'une réservation </li>
                    </ul> <br>

                    <h2> Détails d'un lot </h2> <br>
                    <p>
                        Un clic sur le pictogramme correspondant à la nature du lot, affiché au début de chaque ligne (ou, sur un petit écran tel qu'un téléphone mobile, au début de chaque bloc correspondant à un lot) permet d'accéder à quelques informations complémentaires sur le lot en question, dont notamment les détails sur les lots secondaires associés et le plan de vente éventuel. Toutes les informations déjà présentes sur le listing sont également disponibles.
                    </p>
                    <p>
                        Pour consulter un plan de vente, cliquez sur l'icône "pdf" nommée "Visualiser le document" précédant le titre du document.
                    </p>
                </template>

                <template v-if="$route.path === '/contacts'">
                    <p>
                        La rubrique "Contacts" centralise l'ensemble des contacts pour lesquels vous avez effectué une demande de
                        <span v-if="getConfig('sales.enabled', false)">dénonciation, une option ou une réservation.</span>
                        <span v-else>dénonciation ou une option.</span>
                        Cette page est accessible par le menu principal et à partir de la zone "Mes derniers contacts" de la page d'accueil.
                    </p>
                    <p>
                        Un listing similaire au listing de cette page est également disponible sur les fiches détaillées des programmes, filtré automatiquement sur les contacts relatifs au programme en question.
                    </p>

                    <h2> Outils de navigation </h2> <br>
                    <p>
                        En haut de page, deux outils de navigation sont mis à disposition pour faciliter la consultation des contacts :
                    </p>
                    <ul>
                        <li> Tri par différents critères, par exemple le nom du contact ou sa ville. Possibilité de choisir ordre croissant ou décroissant en cliquant sur l'icône à côté de la zone </li>
                        <li> Pagination : Dans le cas où la liste contient un grand nombre de contacts, la pagination permet de passer d'une page à l'autre (première, dernière, précédente, suivante, ou en cliquant directement sur un numéro de page) </li>
                    </ul> <br>

                    <h2> Recherche des contacts </h2> <br>
                    <p>
                        En haut de page, deux outils de recherche sont mis à disposition pour faciliter la recherche par différents critères.
                    </p>

                    <ul>
                        <li>
                            Recherche rapide par champ de saisie libre : Permet d'accéder aux contacts par leur nom, adresse e-mail ou numéro de téléphone
                        </li>
                        <li>
                            Zone "+ de filtres" : Un clic sur le lien "+ de filtres" ouvre une zone contenant plusieurs critères de recherche différents, notamment :
                            <ul>
                                <li> Ville ou code postal</li>
                                <li> Programme lié </li>
                                <li> Activités réalisées :
                                    <span v-if="getConfig('sales.enabled', false)">Dénonciation, option ou réservation</span>
                                    <span v-else>Dénonciation ou option</span>
                                    réalisée à partir de votre espace
                                </li>
                                <li> Statut des options
                                    <span v-if="getConfig('sales.enabled', false)">et statut des réservations</span>
                                    : L'état d'avancement des options
                                    <span v-if="getConfig('sales.enabled', false)">ou des projets d'acquisition</span>
                                </li>
                            </ul>
                            Les résultats sont affichés lorsque la recherche est lancée en cliquant sur le bouton "Rechercher". La zone "+ de filtres" peut de nouveau être masquée en cliquant sur le bouton désormais appelé "- de filtres"
                        </li>
                    </ul> <br>

                    <h2> Création d'un nouveau contact </h2> <br>
                    <p>
                        Pour créer un nouveau contact, vous devez procéder par une demande de dénonciation.
                    </p>

                    <h2> Dénonciation d'un contact </h2> <br>
                    <p>
                        La dénonciation d'un contact est accessible par le bouton "Dénoncer" en haut du listing des contacts, au niveau de chaque contact du listing (pictogramme dans la colonne "Actions") et à partir des fiches contact et des fiches programme.
                    </p>
                    <p v-if="getConfig('contacts.denonciation_mandatory_fields.program.required', true)">
                        Lors de l'envoi d'une demande de dénonciation, le choix d'un contact et d'un programme est obligatoire. En fonction du point de départ, ces champs peuvent être pré-renseignés : Par exemple, le contact est pré-renseigné lorsqu'on accède à la dénonciation à partir de la ligne qui lui correspond sur la liste des contacts ou à partir de sa fiche, et le programme est pré-renseigné lorsqu'on accède à la demande de dénonciation à partir de la fiche programme.
                    </p>
                    <p v-else>
                        Lors de l'envoi d'une demande de dénonciation, le choix d'un contact existant ou l'ajout d'un nouveau contact est obligatoire.
                    </p>
                    <p v-if="getConfig('options.denonciation_must_be_validated', false)">
                        La validation de votre demande de dénonciation par le promoteur est obligatoire avant toute pose d'option pour ce
                        <span v-if="getConfig('contacts.denonciation_mandatory_fields.program.required', true)">
                            contact et pour le programme en question.
                        </span>
                        <span v-else>contact.</span> Tant que votre demande n'a pas été validée, le contact reste inaccessible sur les fiches options.
                    </p>
                    <p v-else>
                        Dès l'envoi de votre dénonciation, le contact devient accessible sur les fiches options.
                    </p>
                    <div v-if="getConfig('sales.enabled', false)">
                        <p v-if="getConfig('sales.denonciation_must_be_validated', false)">
                            <span v-if="getConfig('options.denonciation_must_be_validated', false)">La </span>
                            <span v-else>En revanche, la </span>
                            validation de votre demande de dénonciation par le promoteur est obligatoire
                            <span v-if="getConfig('options.denonciation_must_be_validated', false)">également</span>
                            avant toute réservation pour ce
                            <span v-if="getConfig('contacts.denonciation_mandatory_fields.program.required', true)">
                                contact et pour le programme en question.
                            </span>
                            <span v-else>contact.</span> Tant que votre demande n'a pas été validée, le contact reste inaccessible lors de la réalisation d'une réservation.
                        </p>
                        <p v-else>
                            <span v-if="getConfig('options.denonciation_must_be_validated', false)">En revanche, dès </span>
                            <span v-else>Dès </span>
                            l'envoi de votre dénonciation, le contact devient accessible
                            <span v-if="getConfig('options.denonciation_must_be_validated', false)"></span>
                            <span v-else>également</span>
                            pour les réservations.
                        </p>
                    </div>

                    <h3> Dénonciation d'un nouveau contact </h3>
                    <p>
                        La dénonciation d'un nouveau contact est accessible en haut de la liste des contacts et sur les fiches programme à partir des liens "Dénoncer".
                    </p>
                    <p>
                        Le formulaire de la demande de dénonciation consiste en champs de saisie correspondant au nom et aux coordonnées du contact et du choix d'un programme. Il est également possible d'adresser un commentaire facultatif au promoteur.
                    </p>
                    <p>
                        Lorsque la demande est envoyée en cliquant sur le bouton "Créer", les contacts déjà existants sont parcourus dans le but de vérifier les correspondances éventuelles : Si un ou plusieurs contacts ayant le même nom ou adresse e-mail sont retrouvés, vous avez la possibilité de choisir une de ces correspondances, ou de cliquer sur "Nouveau", ce qui permet de créer une toute nouvelle fiche contact.
                    </p>

                    <h3> Nouvelle dénonciation d'un contact existant </h3>
                    <p>
                        La dénonciation d'un contact existant est accessible à partir de la ligne qui lui correspond sur la liste des contacts et à partir de sa fiche.
                    </p>
                    <p>
                        Le formulaire est simplifié comparé à la dénonciation d'un nouveau contact : Il suffit de choisir le programme concerné (si non pré-renseigné) et valider. Il est également possible d'adresser un commentaire facultatif au promoteur.
                    </p>

                    <h3> Traitement des demandes de dénonciation </h3>
                    <p>
                        Une demande de dénonciation envoyée est immédiatement consultable sur la liste des contacts et sur la fiche du contact avec mention "En attente de validation".
                    </p>
                    <p>
                        La demande peut ensuite être acceptée ou rejetée par le promoteur. Dès le traitement de la demande, vous recevez un avertissement par e-mail et l'état de la dénonciation est mis à jour dans votre espace, avec mention "Rejetée" ou "Validée". Le motif de la décision, si renseigné par le promoteur, est consultable sur la liste des contacts en survolant (ordinateur desktop) ou en appuyant sur (appareils mobiles) la dénonciation dans la colonne "Dernières activités".
                    </p>
                    <p v-if="getConfig('options.denonciation_must_be_validated', false)">
                        La validation de votre demande de dénonciation par le promoteur est obligatoire avant toute pose d'option pour ce
                        <span v-if="getConfig('contacts.denonciation_mandatory_fields.program.required', true)">
                            contact et pour le programme en question.
                        </span>
                        <span v-else>contact.</span> Tant que votre demande n'a pas été validée, le contact reste inaccessible sur les fiches options.
                    </p>
                    <p v-else>
                        Dès l'envoi de votre dénonciation, le contact devient accessible sur les fiches options.
                    </p>
                    <div v-if="getConfig('sales.enabled', false)">
                        <p v-if="getConfig('sales.denonciation_must_be_validated', false)">
                            <span v-if="getConfig('options.denonciation_must_be_validated', false)">La </span>
                            <span v-else>En revanche, la </span>
                            validation de votre demande de dénonciation par le promoteur est obligatoire
                            <span v-if="getConfig('options.denonciation_must_be_validated', false)">également</span>
                            avant toute réservation pour ce
                            <span v-if="getConfig('contacts.denonciation_mandatory_fields.program.required', true)">
                                contact et pour le programme en question.
                            </span>
                            <span v-else>contact.</span> Tant que votre demande n'a pas été validée, le contact reste inaccessible lors de la réalisation d'une réservation.
                        </p>
                        <p v-else>
                            <span v-if="getConfig('options.denonciation_must_be_validated', false)">En revanche, dès </span>
                            <span v-else>Dès </span>
                            l'envoi de votre dénonciation, le contact devient accessible
                            <span v-if="getConfig('options.denonciation_must_be_validated', false)"></span>
                            <span v-else>également</span>
                            pour les réservations.
                        </p>
                    </div>

                    <h2> Listing </h2> <br>
                    <p>
                        Le listing des contacts permet de consulter les éléments centraux des contacts, notamment :
                    </p>
                    <ul>
                        <li> Le nom et les coordonnées </li>
                        <li> Les dates de la première et de la dernière demande de dénonciation </li>
                        <li> Les programmes auxquels le contact a été lié </li>
                    </ul> <br>

                    <h3> Activités réalisées </h3>
                    <p>
                        La colonne "Dernières activités" contient l'état de la dernière action de chaque "type" :
                        <span v-if="getConfig('sales.enabled', false)">Dénonciation, option ou réservation.</span>
                        <span v-else>Dénonciation ou option.</span>
                    </p>
                    <p>
                        Dans le cas où vous avez effectué plusieurs actions de même type pour un même contact, les labels des actions les plus récentes sont suivis par la mention "+ X" vous informant qu'il existe également d'autres actions, plus anciennes, de même type. Les détails de ces actions peuvent être consultés sur la fiche contact (cliquer sur le nom du contact pour y accéder).
                    </p>

                    <h2> Actions </h2> <br>
                    <p>
                        La colonne "Actions" permet d'effectuer les actions suivantes :
                    </p>
                    <ul>
                        <li> Dénoncer le contact </li>
                        <li> Poser une option pour ce contact </li>
                        <li v-if="getConfig('sales.enabled', false)"> Faire une réservation pour ce contact </li>
                    </ul>
                </template>

                <template v-if="$route.path.includes('/contacts/')">
                    <p>
                        Les fiches contact permettent de consulter les détails d'un contact, notamment son nom, ses coordonnées, les dates de la première et de la dernière dénonciation ainsi que tout l'historique des différentes activités
                        <span v-if="getConfig('sales.enabled', false)">(dénonciations, options, réservations)</span>
                        <span v-else>(dénonciations, options)</span>
                        effectuées pour ce contact à partir de votre espace.
                    </p>
                    <p>
                        La fiche d'un contact est accessible à partir de la liste des contacts,
                        <span v-if="getConfig('sales.enabled', false)">liste des options, fiches réservation</span>
                        <span v-else>liste des options</span>
                        et la page d'accueil, à partir des zones "Mes derniers contacts" et "Mes dernières options" (cliquer sur le nom du contact).
                    </p>

                    <h2> Activités </h2> <br>
                    <p>
                        La zone "Activités" de la fiche centralise l'ensemble des actions réalisées sur le contact, de la plus récente à la plus ancienne :
                    </p>
                    <ul>
                        <li> L'état de toutes les demandes de dénonciation (en attente, acceptée ou rejetée) y compris les dates de validation ou de refus </li>
                        <li> L'état de toutes les options (en cours, confirmée, annulée…) y compris les dates clés </li>
                        <li v-if="getConfig('sales.enabled', false)"> L'état de toutes les réservations, y compris les dates clés. L'état des réservations est cliquable permettant d'accéder sur la fiche réservation </li>
                        <li> Les programmes (cliquables, permettant d'accéder aux fiches programmes) et les lots (référence, prix de vente) concernés pour chacune des activités </li>
                    </ul> <br>
                    <p>
                        La liste des activités peut être filtrée par type en cliquant sur les boutons respectifs en haut de la liste : Dénonciations,
                        <span v-if="getConfig('sales.enabled', false)">options, réservations.</span>
                        <span v-else>options.</span>
                    </p>
                    <p>
                        Dans le cas où la liste contient un grand nombre d'activités, la pagination accessible en haut de la liste permet de passer d'une page à l'autre (première, dernière, précédente, suivante, ou en cliquant directement sur un numéro de page).
                    </p>

                    <h2> Actions </h2> <br>
                    <p>
                        Les actions disponibles sur la fiche, à partir des liens en haut de page, sont :
                    </p>
                    <ul>
                        <li> Dénonciation du contact </li>
                        <li> Pose d'option pour ce contact </li>
                        <li v-if="getConfig('sales.enabled', false)"> Prise de réservation pour ce contact </li>
                    </ul>
                </template>

                <template v-if="$route.path === '/options'">
                    <p>
                        La rubrique "Options" centralise l'ensemble des options
                        <span v-if="getConfig('sales.enabled', false)">non converties à une réservation</span>
                        posées à partir de votre espace. Cette page est accessible par le menu principal et à partir de la zone "Mes dernières options" de la page d'accueil.
                    </p>
                    <p>
                        Un listing similaire au listing de cette page est également disponible sur les fiches détaillées des programmes, filtrée automatiquement sur les options relatives au programme en question.
                    </p>

                    <h2> Outils de navigation </h2> <br>
                    <p>
                        En haut de page, deux outils de navigation sont mis à disposition pour faciliter la consultation des options :
                    </p>
                    <ul>
                        <li> Tri par différents critères, par exemple la date à laquelle l'option a été posée, le nom du contact ou la référence du lot. Possibilité de choisir ordre croissant ou décroissant en cliquant sur l'icône à côté de la zone </li>
                        <li> Pagination : Dans le cas où la liste contient un grand nombre d'options, la pagination permet de passer d'une page à l'autre (première, dernière, précédente, suivante, ou en cliquant directement sur un numéro de page) </li>
                    </ul> <br>

                    <h2> Recherche des options </h2> <br>
                    <p>
                        En haut de page, deux outils de recherche sont mis à disposition pour faciliter la recherche par différents critères.
                    </p>
                    <ul>
                        <li>
                            Recherche rapide par champ de saisie libre : Permet d'accéder aux options par la référence du lot, le nom, adresse e-mail ou numéro de téléphone du contact ou par le nom du programme
                        </li>
                        <li>
                            Zone "+ de filtres" : Un clic sur le lien "+ de filtres" ouvre une zone contenant plusieurs critères de recherche différents, notamment :
                            <ul>
                                <li>
                                    L'état de l'option (en cours, confirmée, expirée…)
                                </li>
                                <li>
                                    Date de mise à jour
                                </li>
                                <li>
                                    Contact : Commencez à saisir le nom, l'e-mail ou le numéro de téléphone d'un contact et les correspondances vous sont proposées
                                </li>
                                <li>
                                    Programme : Commencez à saisir le nom du programme et les correspondances vous sont proposées
                                </li>
                                <li>
                                    Lot
                                </li>
                            </ul>
                            Les résultats sont affichés lorsque la recherche est lancée à l'aide du bouton "Rechercher". La zone "+ de filtres" peut de nouveau être masquée en cliquant sur le bouton désormais appelé "- de filtres"
                        </li>
                    </ul> <br>

                    <h2> Poser une option </h2> <br>
                    <p>
                        La pose d'option est accessible par le bouton "Poser une option" en haut du listing des options, au niveau de chaque contact du listing des contacts (pictogramme dans la colonne "Actions"), à partir des fiches contact (lien en haut de la page) et à partir du listing des lots (pour les lots dont le statut commercial correspond à "Stock").
                    </p>
                    <p>
                        Le choix d'un contact et d'un lot est obligatoire. En fonction du point de départ, ces champs peuvent être pré-renseignés : Par exemple, le contact est pré-renseigné lorsqu'on accède à la création de l'option à partir de la ligne qui lui correspond sur la liste des contacts ou à partir de sa fiche, et le programme et le lot sont pré-renseignés lorsqu'on accède à la création de l'option à partir d'un lot donné.
                    </p>

                    <h2> Choix du lot </h2> <br>
                    <p>
                        Dans le cas où le lot n'est pas pré-sélectionné, vous êtes d'abord amené(e) à sélectionner le programme, et ensuite un lot parmi les lots disponibles de ce programme. Le programme peut être pré-sélectionné lorsque l'option est créée à partir de la fiche programme sans avoir sélectionné de lot, dans ce cas le choix du lot est suffisant. Vous pouvez faciliter la sélection du lot en saisissant la référence du lot voulu dans le champ correspondant.
                    </p>

                    <h2> Choix du contact </h2> <br>
                    <p>
                        Dans le cas où le contact n'est pas pré-sélectionné, commencez à saisir le nom, l'adresse e-mail ou le numéro de téléphone du contact concerné dans le champ correspondant, et sélectionnez ensuite le contact voulu.
                    </p>
                    <p>
                        Pour créer un nouveau contact, vous devez procéder par la dénonciation.
                    </p>
                    <p v-if="getConfig('options.denonciation_must_be_validated', false)">
                        A noter que la validation de votre demande de dénonciation par le promoteur est obligatoire avant toute pose d'option pour un
                        <span v-if="getConfig('contacts.denonciation_mandatory_fields.program.required', true)">
                            contact et pour le programme en question.
                        </span>
                        <span v-else>contact.</span> Tant que votre demande n'a pas été validée, le contact reste inaccessible sur les fiches options.
                    </p>

                    <h2> Traitement d'une option </h2> <br>
                    <p>
                        Lorsque vous validez une nouvelle option en cliquant sur le bouton "Poser l'option", le statut commercial du lot est modifié en "Option" et la nouvelle option est affichée dans les rubriques "Contacts" et "Options" avec mention "en cours".
                    </p>

                    <h2> Durée d'une option </h2> <br>
                    <p>
                        La durée de validité d'une option est de {{ getConfig('options.duration', 3) }} jours.
                    </p>
                    <p>
                        La date d'expiration de l'option (date de la création de l'option + la durée de validité) est affichée parmi les dates clés sur la liste des options, au niveau du contact, et au niveau du lot sur les listings de lots : Survoler (ordinateurs desktop) ou appuyez sur (appareils mobiles) l'état de l'option.
                    </p>
                    <p>
                        La date d'expiration des lots optionnés en dehors de votre espace est accessible sur les grilles de prix, dans la colonne "Activité".
                    </p>
                    <p>
                        Lorsque la date d'expiration est atteinte, l'option tombe automatiquement : Le lot repasse en stock et l'état de l'option est mis à jour au niveau du contact, du lot, et de la liste des options.
                    </p>

                    <h2> Limites du nombre d'options posées </h2> <br>
                    <p>
                        Vous ne pouvez pas optionner un même lot de nouveau dans les
                        <span v-if="getConfig('options.same_product_cooldown', 1) === 1">24 heures</span>
                        <span v-else>{{ getConfig('options.same_product_cooldown', 1) }} jours</span>
                        de la création de la première option.
                    </p>
                    <p>
                        Vous ne pouvez pas poser plus de {{ getConfig('options.active_max', 5) }} options actives "en cours" (non confirmées) simultanément.
                    </p>

                    <h2> Confirmation d'une option </h2> <br>
                    <p>
                        <span v-if="getConfig('options.confirm_expired_date_extension', 3) > 0">
                            La confirmation d'une option vous permet d'obtenir un délai complémentaire de {{ getConfig('options.confirm_expired_date_extension', 3) }} jours au niveau de la validité de l'option.
                        </span>
                        <span v-else>
                            La confirmation d'une option vous permet de bénéficier d'une option fixe qui ne tombera pas de manière automatique (l'option n'aura plus de date d'expiration).
                        </span>
                    </p>
                    <p>
                        La confirmation des options est accessible dans la colonne "Actions" du listing des contacts, de la fiche contact, du listing des options et du listing des lots, pour les options "en cours".
                    </p>

                    <div v-if="getConfig('options.enable_planned_sign_date', false)">
                        <h3> Date de signature </h3>
                        <p>
                            A la confirmation d'une option, vous êtes amené(e) à renseigner la date de signature prévue. Cette date n'affecte pas la durée de l'option.
                        </p>
                    </div>

                    <h2> Annulation d'une option </h2> <br>
                    <p>
                        L'annulation des options est accessible dans la colonne "Actions" du listing des contacts, de la fiche contact, du listing des options et du listing des lots, pour les options "en cours" et pour les options confirmées.
                    </p>
                    <p>
                        Lorsqu'une option est annulée, l'état de l'option est mis à jour, la date d'annulation est affichée au niveau des dates clés de l'option, et le lot en question repasse en stock.
                    </p>

                    <div v-if="getConfig('sales.enabled', false)">
                        <h2> Transformer une option en une réservation </h2> <br>
                        <p>
                            Une option
                            <span v-if="getConfig('sales.option_must_be_confirmed', false)">confirmée</span>
                            peut être transformée en une réservation à partir de la colonne "Actions" du listing des contacts, de la fiche contact, du listing des options et du listing des lots.
                        </p>
                        <p>
                            Lorsque vous validez la transformation, vous êtes pointé(e) sur une fiche réservation vous permettant de compléter les informations relatives au projet d'acquisition. Le lot obtient le statut "Pré-réservé" et il est retiré des grilles de prix, l'option bascule de la liste des options vers la liste des réservations, et l'historique des actions relatives au contact est mis à jour.
                        </p>
                    </div>

                    <h2> Listing </h2> <br>
                    <p>
                        Le listing des options permet de consulter les éléments centraux des options, notamment :
                    </p>
                    <ul>
                        <li> La référence du lot, cliquable, permettant d'accéder aux détails du lot </li>
                        <li> Caractéristiques principales du lot dont la typologie </li>
                        <li> Dates clés, dont la date de pose d'option et en fonction de l'état de l'option, soit la date d'expiration ou la date d'annulation (le libellé de la date s'affiche au survol de la souris sur un ordinateur desktop et en appuyant sur la date sur un appareil mobile) </li>
                        <li> Le nom du contact (cliquable, permet d'accéder à la fiche contact) et ses coordonnées </li>
                        <li> Informations financières dont le prix de vente et le taux TVA </li>
                        <li> Le programme (cliquable, permet d'accéder à la fiche programme) et sa localisation géographique </li>
                    </ul> <br>

                    <h2> Actions </h2> <br>
                    <p>
                        La colonne "Actions" permet d'effectuer les actions suivantes :
                    </p>
                    <ul>
                        <li> Confirmation des options en cours </li>
                        <li> Annulation des options en cours ou confirmées </li>
                        <li v-if="getConfig('sales.enabled', false)"> Réalisation des réservations </li>
                    </ul> <br>
                    <p>
                        Le lien "Poser une option" en haut de page permet de poser une nouvelle option.
                    </p>
                </template>

                <template v-if="$route.path === '/reservations'">
                    <p>
                        La rubrique "Réservations" centralise l'ensemble des réservations réalisées à partir de votre espace, jusqu'aux ventes finalisées. Cette page est accessible par le menu principal et à partir de la zone "Mes dernières réservations" de la page d'accueil.
                    </p>
                    <p>
                        Un listing similaire au listing de cette page est également disponible sur les fiches détaillées des programmes, filtrée automatiquement sur les réservations relatives au programme en question.
                    </p>

                    <h2> Outils de navigation </h2> <br>
                    <p>
                        En haut de page, deux outils de navigation sont mis à disposition pour faciliter la consultation des réservations :
                    </p>
                    <ul>
                        <li> Tri par différents critères, par exemple la date de pré-réservation, le nom du contact ou du programme ou la référence du lot. Possibilité de choisir ordre croissant ou décroissant en cliquant sur l'icône à côté de la zone </li>
                        <li> Pagination : Dans le cas où la liste contient un grand nombre de réservations, la pagination permet de passer d'une page à l'autre (première, dernière, précédente, suivante, ou en cliquant directement sur un numéro de page) </li>
                    </ul> <br>

                    <h2> Recherche des réservations </h2> <br>
                    <p>
                        En haut de page, deux outils de recherche sont mis à disposition pour faciliter la recherche par différents critères.
                    </p>

                    <ul>
                        <li> Recherche rapide par champ de saisie libre : Permet d'accéder aux réservations par la référence du lot, le nom, adresse e-mail ou numéro de téléphone du contact ou par le nom du programme </li>
                        <li>
                            Zone "+ de filtres" : Un clic sur le lien "+ de filtres" ouvre une zone contenant plusieurs critères de recherche différents, notamment :
                            <ul>
                                <li>
                                    L'état de la réservation (pré-réservé, fixer RDV notaire, VEFA signée...)
                                </li>
                                <li>
                                    Date de mise à jour
                                </li>
                                <li>
                                    Contact : Commencez à saisir le nom, l'e-mail ou le numéro de téléphone d'un contact et les correspondances vous sont proposées
                                </li>
                                <li>
                                    Programme : Commencez à saisir le nom du programme et les correspondances vous sont proposées
                                </li>
                                <li>
                                    Lot
                                </li>
                            </ul>
                            Les résultats sont affichés lorsque la recherche est lancée à l'aide du bouton "Rechercher". La zone "+ de filtres" peut de nouveau être masquée en cliquant sur le bouton désormais appelé "- de filtres"
                        </li>
                    </ul> <br>

                    <h2> Réalisation d'une réservation </h2> <br>
                    <p>
                        <span v-if="getConfig('sales.sale_must_be_created_from_option', false)">
                            La réservation d'un lot se fait par la transformation d'une option
                            <span v-if="getConfig('sales.option_must_be_confirmed', false)">confirmée</span>
                            en une réservation. Cette action
                        </span>
                        <span v-else>
                            La réservation d'un lot
                        </span>
                        peut être faite à partir de la colonne "Actions" du listing des contacts, de la fiche contact, du listing des options et des grilles de prix.
                    </p>
                    <div v-if="getConfig('sales.sale_must_be_created_from_option', false)"></div>
                    <div v-else>
                        <p>
                            Le choix d'un contact et d'un lot est obligatoire. En fonction du point de départ, ces champs peuvent être pré-renseignés : Par exemple, le contact est pré-renseigné lorsqu'on accède à la réservation à partir de la ligne qui lui correspond sur la liste des contacts ou à partir de sa fiche, et le programme et le lot sont pré-renseignés lorsqu'on accède à la création de la réservation à partir d'un lot donné.
                        </p>

                        <h3> Choix du lot </h3> <br>
                        <p>
                            Dans le cas où le lot n'est pas pré-sélectionné, vous êtes d'abord amené(e) à sélectionner le programme, et ensuite un lot parmi les lots disponibles de ce programme. Le programme peut être pré-sélectionné lorsque la réservation est créée à partir de la fiche programme sans avoir sélectionné de lot, dans ce cas le choix du lot est suffisant. Vous pouvez faciliter la sélection du lot en saisissant la référence du lot voulu dans le champ correspondant.
                        </p>

                        <h3> Choix du contact </h3> <br>
                        <p>
                            Dans le cas où le contact n'est pas pré-sélectionné, commencez à saisir le nom, l'adresse e-mail ou le numéro de téléphone du contact concerné dans le champ correspondant, et sélectionnez ensuite le contact voulu.
                        </p>
                        <p>
                            Pour créer un nouveau contact, vous devez procéder par la dénonciation.
                        </p>
                        <p v-if="getConfig('sales.denonciation_must_be_validated', false)">
                            A noter que la validation de votre demande de dénonciation par le promoteur est obligatoire avant toute réservation pour un
                            <span v-if="getConfig('contacts.denonciation_mandatory_fields.program.required', true)">
                                contact et pour le programme en question.
                            </span>
                            <span v-else>contact.</span> Tant que votre demande n'a pas été validée, le contact reste inaccessible lors de la réalisation d'une réservation.
                        </p>
                    </div>

                    <p>
                        Lors de la validation de votre réservation, vous êtes pointé(e) sur une fiche réservation vous permettant de compléter les informations relatives au projet d'acquisition. Le lot obtient le statut "Pré-réservé" et il est retiré des grilles de prix, l'option préalable
                        <span v-if="getConfig('sales.sale_must_be_created_from_option', false)"></span>
                        <span v-else>éventuelle</span>
                        bascule de la liste des options vers la liste des réservations, et l'historique des actions relatives au contact est mis à jour.
                    </p>

                    <h2> Listing </h2> <br>
                    <p>
                        Le listing des réservations permet de consulter les éléments centraux des réservations, notamment :
                    </p>
                    <ul>
                        <li> L'état de la réservation </li>
                        <li> Le nom du programme, la référence et la typologie du lot </li>
                        <li> Le nom et les coordonnées du contact </li>
                        <li> Le prix de la réservation et le taux TVA </li>
                        <li> Quelques dates clés dont la date de pré-réservation, signature du contrat préliminaire (les libellés des dates s'affichent au survol de la souris sur un ordinateur desktop et en appuyant sur la date sur un appareil mobile) </li>
                    </ul> <br>
                    <p>
                        Un clic sur un bloc "réservation" vous permet d'accéder à la fiche réservation.
                    </p>
                </template>

                <template v-if="$route.path.includes('/reservations/')">
                    <p>
                        La fiche réservation permet de suivre l'avancement du projet d'acquisition et de compléter un certain nombre d'informations relatives au projet. Elle consiste en plusieurs parties :
                    </p>

                    <h2>Génération du contrat</h2> <br>
                    <p>
                        Possibilité de consulter différentes versions du contrat de réservation et de lancer le process de la signature électronique
                    </p>

                    <h2>Synthèse</h2> <br>
                    <ul>
                        <li>
                            Nom du contact, cliquable, permettant d'accéder à la fiche contact. Au survol de la souris (ordinateurs desktop), possibilité de consulter les coordonnées du contact
                        </li>
                        <li>
                            Références et caractéristiques principales des lots
                        </li>
                        <li>
                            Statut de la réservation
                        </li>
                        <li>
                            Nom et la ville du programme, cliquables, permettant d'accéder à la fiche programme
                        </li>
                        <li>
                            Date d'actabilité du programme
                        </li>
                        <li>
                            Dates clés, dont la date de la pré-réservation, dates de signature, date prévisionnelle de livraison
                        </li>
                    </ul> <br>

                    <h2>Onglet "Acquisition"</h2> <br>
                    <ul>
                        <li>
                            Lots
                            <ul>
                                <li>
                                    Détails des informations financières lot par lot (prix de vente, remise, prix négocié, prix total)
                                </li>
                            </ul>
                        </li>
                        <li>
                            Caractéristiques
                            <ul>
                                <li>
                                    Zone modifiable lorsque la réservation est en état "pré-réservation" (contrat non encore signé), contenant notamment les champs Taux TVA, Destination du bien, Type d'acquisition du bien
                                </li>
                            </ul>
                        </li>
                        <li>
                            Dépôt de garantie
                            <ul>
                                <li>
                                    Zone modifiable lorsque la réservation est en état "pré-réservation" (contrat non encore signé), permettant de renseigner les dépôts de garantie
                                </li>
                            </ul>
                        </li>
                        <li>
                            Financement
                            <ul>
                                <li>
                                    Zone modifiable lorsque la réservation est en état "pré-réservation" (contrat non encore signé), permettant de renseigner les informations relatives au financement du projet d'acquisition (les prêts dont les dates d'accord et les dates d'édition, apport personnel...)
                                </li>
                            </ul>
                        </li>
                    </ul> <br>

                    <h2>Onglet "Acquéreurs"</h2> <br>
                    <p>
                        Zone modifiable lorsque la réservation est en état "pré-réservation" (contrat non encore signé), permettant de :
                    </p>
                    <ul>
                        <li>
                            Compléter les informations de l'acquéreur principal (par exemple, en ajoutant la date de naissance, la situation conjugale et autres informations nécessaires pour le contrat de réservation)
                        </li>
                        <li>
                            Ajouter d'un ou plusieurs co-acquéreurs et compléter leurs informations comme celles de l'acquéreur principal
                        </li>
                        <li>
                            Renseigner le nom et les coordonnées des notaires des acquéreurs : Cliquer sur "Modifier" au niveau de l'acquéreur concerné, puis sur l'onglet "Notaire" en haut de la fenêtre. L'onglet "Acquéreur" permet de revenir sur les informations détaillées sur l'acquéreur
                        </li>
                    </ul>
                    <br>

                    <h2>Onglet "Documents"</h2> <br>
                    <p>
                        La zone partagée en quatre blocs, Contrats, Documents clients, Notification, Autres documents, permet de consulter, ajouter et supprimer des documents relatifs au projet d'acquisition :
                    </p>
                    <ul>
                        <li>
                            Pour ajouter un document "libre" (accessible au niveau des blocs Contrats et Autres documents), cliquer sur le bouton "Ajouter" du bloc correspondant, saisissez le titre du document, associez le document concerné en cliquant sur le champ "Document" et validez à partir du bouton "Créer"
                        </li>
                        <li>
                            Pour ajouter un document prédéfini (dont le contrat de réservation, les notifications et "Documents clients"), cliquez sur le pictogramme "pdf" au début des lignes correspondantes. Le titre de ces documents prédéfinis est pré-renseigné et non modifiable. Associez le document concerné en cliquant sur le champ "Document" et validez à partir du bouton "Créer"
                        </li>
                        <li>
                            Pour supprimer un document, survolez (ordinateurs desktop) ou appuyez sur (appareils mobiles) le document, puis cliquez sur l'icône "poubelle". Notez que les documents relatifs au programme, mis à disposition par le promoteur, ne peuvent être supprimés
                        </li>
                    </ul>
                </template>

                <template v-if="$route.path === '/messages'">
                    <p>
                        La rubrique "Messages" vous permet de contacter un interlocuteur du promoteur et de consulter tous les précédents échanges.
                    </p>
                    <p>
                        Les discussions existantes sont classées de la plus récente à la plus ancienne dans la liste des discussions. Les messages peuvent être consultés en cliquant sur les noms des interlocuteurs.
                    </p>

                    <h2> Envoyer un message </h2> <br>
                    <p>
                        Pour commencer une discussion avec un nouvel interlocuteur du promoteur, cliquez sur le pictogramme "Plus" + et appuyez sur un intervenant.
                    </p>
                    <p>
                        Notez que les interlocuteurs avec lesquels vous avez déjà échangé préalablement ne sont plus listés parmi les destinataires : Toute discussion existante peut être consultée et continuée en cliquant sur le nom de l'interlocuteur dans la liste des discussions.
                    </p>
                    <p>
                        Saisissez votre message dans la zone "Envoyer un message" (pour toute nouvelle discussion) ou "Répondre" (pour toute discussion existante) et cliquez sur la flèche "Envoyer" à proximité de la zone de saisie pour valider.
                    </p>

                    <h2> Réception d'un message </h2> <br>
                    <p>
                        Lorsqu'un interlocuteur du promoteur vous adresse un message, vous recevez un avertissement par e-mail. Pour consulter ce message et pour y répondre, cliquez sur le nom de l'interlocuteur dans la liste des discussions.
                    </p>
                </template>

                <template v-if="$route.path === '/mon-compte'">
                    <h2> Modification du mot de passe </h2> <br>
                    <p>
                        Cette rubrique vous permet de modifier le mot de passe que vous utilisez pour vous connecter à votre espace.
                    </p>
                    <p>
                        Saisissez votre ancien mot de passe, ensuite le nouveau, confirmez le nouveau mot de passe et validez par le bouton "Envoyer". Un message de confirmation est affiché sur l'écran lorsque le changement est fait avec succès.
                    </p>
                </template>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer />

                <v-btn @click="isOpen = false" depressed small>
                    Fermer
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'GuidedTourDialog',

    data: () => ({
        isOpen: false
    })
};
</script>
