var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"text-none grey--text text--darken-1",attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fas fa-user-circle ")]),_vm._v(" "+_vm._s(_vm.$store.state.application.user.name.slice(0, 15))+" "),(_vm.$store.state.application.user.name.length > 15)?[_vm._v(" ... ")]:_vm._e(),_c('v-icon',{attrs:{"right":""}},[_vm._v(" fas fa-caret-down ")])],2)]}}])},[_c('v-list',{staticClass:"py-0"},[_c('v-list-item',{staticClass:"py-1 grey lighten-3"},[_c('v-list-item-avatar',{staticClass:"contact-foreground white--text text-uppercase headline"},[_vm._v(" "+_vm._s(_vm.nameFirstLetter)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$store.state.application.user.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$store.state.application.user.email)+" ")])],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"to":"/mon-compte"}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-user ")])],1),_c('v-list-item-title',[_vm._v(" Mon compte ")])],1),_c('FinancingCalculator',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-action',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-euro-sign ")])],1),_c('v-list-item-title',[_vm._v(" Calculette financière ")])],1)]}}])}),_c('GuidedTourDialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-action',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-question-circle ")])],1),_c('v-list-item-title',[_vm._v(" Visite guidée ")])],1)]}}])}),(_vm.displayDebugAccounts)?_c('DebugAccounts',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-action',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-user-shield ")])],1),_c('v-list-item-title',[_vm._v(" Connexions ")])],1)]}}],null,false,2224135590)}):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.disconnect()}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v(" fas fa-sign-out-alt ")])],1),_c('v-list-item-title',[_vm._v(" Déconnexion ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }