<template>
    <v-dialog v-model="isOpen" max-width="575">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <LoginCard :isSessionExpired="true" @close="isOpen = false" />

        <!-- <v-card>
            <v-card-title class="primary py-1 subtitle white--text">
                Session expirée
            </v-card-title>

            <v-card-text>
                <p class="pt-4">
                    Votre session à expirée, vous pouvez choisir de rester sur cette page ou vous pouvez vous reconnecter.
                </p>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="isOpen = false" small> Rester </v-btn>
                <v-spacer />
                <v-btn @click="reconnect()" color="primary" small> Reconnexion </v-btn>
            </v-card-actions>
        </v-card> -->
    </v-dialog>
</template>

<script>
import LoginCard from '../login/PartnerLoginCard.vue';

export default {
    components: {
        LoginCard
    },

    computed: {
        isOpen: {
            get() {
                return this.$store.state.application.sessionExpiredDialog;
            },

            set(isOpen) {
                this.$store.commit('application/setSessionExpiredDialog', isOpen);
            }
        }
    },

    methods: {
        reconnect() {
            this.isOpen = false;
            this.$router.push('/connexion');
        }
    }
};
</script>