<template>
    <v-dialog v-model="isOpen" width="60%">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Debug
                </v-toolbar-title>

                <v-select v-if="displayProviders" v-model="providerCode" :items="providerCodes" class="ml-4" placeholder="Provider Code" style="max-width: 220px" :menu-props="{ bottom: true, offsetY: true }" hide-details outlined dense />

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-sheet class="pa-4" color="#eee">
                <v-row>
                    <v-col v-if="displayProviders" cols="6">
                        <v-sheet class="pa-2" color="white" rounded>
                            <h2 class="title primary--text mb-2"> Utilisateur Axessia </h2>

                            <v-text-field v-model="usersSearch" class="mb-2" placeholder="Recherche..." outlined dense hide-details />

                            <v-list style="max-height: 310px; overflow-y: auto;">
                                <v-list-item v-for="user of displayUsers" :key="user.IdUser" @click="createSession(user)" class="mr-2">
                                    <v-list-item-avatar>
                                        <v-icon color="light-blue darken-2" dark> fas fa-user </v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ user.Account }}
                                        </v-list-item-title>

                                        <v-list-item-subtitle>
                                            {{ user.vEmail }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-sheet>
                    </v-col>

                    <v-col :cols="!displayProviders ? 12 : 6">
                        <v-sheet class="pa-2" color="white" rounded>
                            <h2 class="title primary--text mb-2"> Partenaires </h2>

                            <v-text-field v-model="partnersSearch" class="mb-2" placeholder="Recherche..." outlined dense hide-details />

                            <v-list style="max-height: 310px; overflow-y: auto;">
                                <v-list-item v-for="partner of displayPartners" :key="partner.IdPartner" @click="createSession(partner)" class="mr-2">
                                    <v-list-item-avatar>
                                        <v-icon color="light-blue darken-2" dark> fas fa-user-tie </v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ partner.Name }}
                                        </v-list-item-title>

                                        <v-list-item-subtitle>
                                            {{ partner.Email }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-divider />

            <v-card-actions>
                <v-spacer />

                <v-btn @click="isOpen = false" depressed small> Fermer </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'DebugAccounts',

    data: () => ({
        isOpen: false,
        isLoading: false,
        users: [],
        partners: [],
        displayUsers: [],
        displayPartners: [],
        providerCodes: [],
        providerCode: null,

        usersSearch: null,
        usersSearchTimeout: null,
        partnersSearch: null,
        partnersSearchTimeout: null
    }),

    methods: {
        async createSession(account) {
            try {
                this.setLoading(true);

                const body = { account: { providerCode: this.providerCode } };
                this.$store.commit('application/setProviderCode', this.providerCode);
                if (account.IdPartner) {
                    body.account.id = account.IdPartner;
                    body.account.type = 'partner';
                    body.account.email = account.Email;
                } else if (account.IdUser) {
                    body.account.id = account.IdUser;
                    body.account.type = 'user';
                    body.account.email = account.vEmail;
                }

                const { success, err } = await this.repos.debug.createSession(body);
                if (success) {
                    if (this.$route.path === '/connexion') {
                        this.$router.push('/');
                        this.isOpen = false;
                    } else {
                        location.reload();
                    }
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la création de la session',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async fetchAccounts() {
            try {
                this.setLoading(true);

                const query = { providerCode: this.providerCode };
                const { users, partners, err } = await this.repos.debug.getAccounts(query);
                if (err) {
                    throw new Error();
                } else {
                    this.users = users;
                    this.displayUsers = users.slice(0, 5);
                    this.partners = partners;
                    this.displayPartners = partners.slice(0, 5);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la récupération des comptes utilisateurs',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async fetchProviders() {
            try {
                const { providers } = await this.repos.providers.getAvailables();
                this.providerCodes = providers;
            } catch (err) {
                console.error(err);
            }
        },

        doUsersSearch() {
            this.displayUsers = this.users.filter((u) => {
                return !this.usersSearch || this.usersSearch.length < 3 ||
                    (u.Account && u.Account.toLowerCase().includes(this.usersSearch.toLowerCase())) ||
                    (u.vEmail && u.vEmail.toLowerCase().includes(this.usersSearch.toLowerCase()));
            });
        },

        doPartnersSearch() {
            this.displayPartners = this.partners.filter((p) => {
                return !this.partnersSearch || this.partnersSearch.length < 3 ||
                    (p.Name && p.Name.toLowerCase().includes(this.partnersSearch.toLowerCase())) ||
                    (p.Email && p.Email.toLowerCase().includes(this.partnersSearch.toLowerCase()));
            });
        }
    },

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.providerCode = this.$store.state.application.providerCode;
                this.fetchProviders();
            }
        },

        providerCode() {
            this.fetchAccounts();
        },

        usersSearch() {
            clearTimeout(this.usersSearchTimeout);
            this.usersSearchTimeout = setTimeout(() => {
                this.doUsersSearch();
            }, 500);
        },

        partnersSearch() {
            clearTimeout(this.partnersSearchTimeout);
            this.partnersSearchTimeout = setTimeout(() => {
                this.doPartnersSearch();
            }, 500);
        }
    },

    computed: {
        displayProviders() {
            return location.host === 'localhost:8080' || (this.$store.state.application.user && this.$store.state.application.user.isDP2I);
        }
    },

    created() {
        window.addEventListener('keyup', (e) => {
            if (e.altKey && e.ctrlKey && e.key === 'a') {
                this.isOpen = true;
            }
        });
    }
};
</script>