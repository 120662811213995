import Vue from 'vue';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

Vue.filter('toDateStr', (str, formatStr) => {
    let date = '--/--/----';
    try {
        if (str) {
            date = format(new Date(str), formatStr, { locale: fr });
        }
    } catch (err) {
        console.error(err);
    }
    return date;
});

Vue.filter('toDate', (str) => {
    let date = '--/--/----';
    try {
        if (str) {
            date = format(new Date(str), 'dd/MM/yyyy', { locale: fr });
        }
    } catch (err) {
        console.error(err);
    }
    return date;
});

Vue.filter('toTime', (str, seconds = true) => {
    let date = '--:--:--';
    try {
        if (str) {
            date = format(new Date(str), seconds ? 'HH:mm:ss' : 'HH:mm', { locale: fr });
        }
    } catch (err) {
        console.error(err);
    }
    return date;
});

Vue.filter('toDatetime', (str, noSeconds = false) => {
    let date = '--/--/---- --:--:--';
    try {
        if (str) {
            date = format(new Date(str), 'dd/MM/yyyy HH:mm:ss', { locale: fr });
            if (noSeconds) {
                date = format(new Date(str), 'dd/MM/yyyy HH:mm', { locale: fr });
            }
        }
    } catch (err) {
        console.error(err);
    }
    return date;
});

Vue.filter('toMonthYearDate', (str) => {
    let date = '';
    try {
        if (str) {
            date = format(new Date(str), 'MMMM yyyy', { locale: fr });
        }
    } catch (err) {
        console.error(err);
    }
    return date;
});

Vue.filter('toDeliveryDateType', (str) => {
    switch (str) {
        case 30:
            return 'Immédiate';
        case 1:
            return 'Année';
        case 2:
            return '1er semestre';
        case 3:
            return '2ème semestre';
        case 4:
            return '1er trimestre';
        case 5:
            return '2ème trimestre';
        case 6:
            return '3ème trimestre';
        case 7:
            return '4ème trimestre';
        case 8:
            return 'janvier';
        case 9:
            return 'février';
        case 10:
            return 'mars';
        case 11:
            return 'avril';
        case 12:
            return 'mai';
        case 13:
            return 'juin';
        case 14:
            return 'juillet';
        case 15:
            return 'août';
        case 16:
            return 'septembre';
        case 17:
            return 'octobre';
        case 18:
            return 'novembre';
        case 19:
            return 'décembre';
        default:
            return '';
    }
});

Vue.filter('toYearDate', (str) => {
    let date = '';
    try {
        if (str) {
            date = format(new Date(str), 'yyyy', { locale: fr });
        }
    } catch (err) {
        console.error(err);
    }
    return date;
});

Vue.filter('toDateDatabase', (str) => {
    let date = '----/--/--';
    try {
        if (str) {
            date = format(new Date(str), 'yyyy-MM-dd', { locale: fr });
        }
    } catch (err) {
        console.error(err);
    }
    return date;
});

Vue.filter('toDayMonthYearDate', (str) => {
    let date = '';
    try {
        if (str) {
            date = format(new Date(str), 'dd MMMM yyyy', { locale: fr });
        }
    } catch (err) {
        console.error(err);
    }
    return date;
});