import Vue from 'vue';

import auth from './repositories/auth.js';
import debug from './repositories/debug.js';
import sales from './repositories/sales.js';
import places from './repositories/places.js';
import actions from './repositories/actions.js';
import options from './repositories/options.js';
import contacts from './repositories/contacts.js';
import partners from './repositories/partners.js';
import products from './repositories/products.js';
import programs from './repositories/programs.js';
import dashboard from './repositories/dashboard.js';
import documents from './repositories/documents.js';
import messaging from './repositories/messaging.js';
import providers from './repositories/providers.js';
import parameters from './repositories/parameters.js';
import application from './repositories/application.js';
import actualities from './repositories/actualities.js';
import partnerActivities from './repositories/partnerActivities.js';
import programPartnerGroups from './repositories/programPartnerGroups.js';

Vue.mixin({
    data: () => ({
        repos: {
            auth,
            debug,
            sales,
            places,
            actions,
            options,
            contacts,
            partners,
            products,
            programs,
            dashboard,
            documents,
            messaging,
            providers,
            parameters,
            application,
            actualities,
            partnerActivities,
            programPartnerGroups
        }
    }),

    methods: {
        setLoading(loading) {
            this.$store.commit('application/setLoading', loading);
        },

        getConfig(path, defaultValue) {
            if (!this.$store.state.application.config) {
                return defaultValue;
            } else {
                if (path === '*') {
                    return this.$store.state.application.config;
                } else {
                    return path.split('.').reduce((a, b) => a[b], this.$store.state.application.config);
                }
            }
        },

        getParameter(code) {
            return this.$store.getters['parameters/getParameter'](code);
        },

        getParameterValue(code, value) {
            return this.$store.getters['parameters/getParameterValue'](code, value);
        }
    }
});