import Vue from 'vue';

import numeral from 'numeral';

numeral.register('locale', 'fr', {
    delimiters: {
        thousands: ' ',
        decimal: ', '
    }
});

numeral.locale('fr');

Vue.filter('toCurrencyString', function (input, decimal) {
    let number = parseFloat(input);
    if (isNaN(number)) {
        number = 0;
    }
    // return numeral(number).format(decimal ? '0,0.[00]' : '0,0') + ' €';
    return numeral(number).format(decimal ? '0,0.00' : '0,0').replace(', ', ',') + ' €';
});
