<template>
    <v-menu offset-y left>
        <template v-slot:activator="{ on }">
            <v-btn class="text-none grey--text text--darken-1" text v-on="on">
                <v-icon left> fas fa-user-circle </v-icon>
                {{ $store.state.application.user.name.slice(0, 15) }}
                <template v-if="$store.state.application.user.name.length > 15">
                    ...
                </template>
                <v-icon right> fas fa-caret-down </v-icon>
            </v-btn>
        </template>

        <v-list class="py-0">
            <v-list-item class="py-1 grey lighten-3">
                <v-list-item-avatar class="contact-foreground white--text text-uppercase headline">
                    {{ nameFirstLetter }}
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title class="title"> {{ $store.state.application.user.name }} </v-list-item-title>
                    <v-list-item-subtitle> {{ $store.state.application.user.email }} </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider />

            <v-list-item to="/mon-compte">
                <v-list-item-action>
                    <v-icon small> fas fa-user </v-icon>
                </v-list-item-action>

                <v-list-item-title> Mon compte </v-list-item-title>
            </v-list-item>

            <FinancingCalculator>
                <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">
                        <v-list-item-action>
                            <v-icon small> fas fa-euro-sign </v-icon>
                        </v-list-item-action>

                        <v-list-item-title> Calculette financière </v-list-item-title>
                    </v-list-item>
                </template>
            </FinancingCalculator>

            <GuidedTourDialog>
                <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">
                        <v-list-item-action>
                            <v-icon small> fas fa-question-circle </v-icon>
                        </v-list-item-action>

                        <v-list-item-title> Visite guidée </v-list-item-title>
                    </v-list-item>
                </template>
            </GuidedTourDialog>

            <DebugAccounts v-if="displayDebugAccounts">
                <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">
                        <v-list-item-action>
                            <v-icon small> fas fa-user-shield </v-icon>
                        </v-list-item-action>

                        <v-list-item-title> Connexions </v-list-item-title>
                    </v-list-item>
                </template>
            </DebugAccounts>            

            <!-- <v-list-item to="/historique">
                <v-list-item-action>
                    <v-icon small> fas fa-history </v-icon>
                </v-list-item-action>

                <v-list-item-title> Historique </v-list-item-title>
            </v-list-item> -->

            <!-- <v-list-item to="/parametres">
                <v-list-item-action>
                    <v-icon small> fas fa-cogs </v-icon>
                </v-list-item-action>

                <v-list-item-title> Paramètres </v-list-item-title>
            </v-list-item> -->

            <v-list-item @click="disconnect()">
                <v-list-item-action>
                    <v-icon color="red" small> fas fa-sign-out-alt </v-icon>
                </v-list-item-action>

                <v-list-item-title> Déconnexion </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import DebugAccounts from '../debug/DebugAccounts.vue';
import GuidedTourDialog from '../dialogs/GuidedTourDialog.vue';
import FinancingCalculator from '../dialogs/FinancingCalculator.vue';

export default {
    name: 'UserProfile',

    components: {
        DebugAccounts,
        GuidedTourDialog,
        FinancingCalculator
    },

    methods: {
        async disconnect() {
            const type = this.$store.state.application.user.type;
            this.$store.commit('application/disconnect');
            await this.repos.auth.disconnect();
            if (type === 'partner') {
                this.$router.push('/connexion');
            } else if (type === 'user') {
                this.$router.push('/connexion/utilisateur');
            }
        }
    },

    computed: {
        nameFirstLetter() {
            return this.$store.state.application.user.name.slice(0, 1);
        },

        displayDebugAccounts() {
            return this.$store.state.application.user.isAdmin;
        }
    }
};
</script>

<style lang="scss">
.introJs * {
    font-size: 13px;
}

.introJs .introjs-tooltip-title {
    font-weight: bold;
    font-size: 16px;
}

.introJs .introjs-button {
    background-color: #08c;
    color: white;
    font-weight: normal;
    text-shadow: 0px 0px 0 #fff;
    font-size: 12px;
}

.introjs-tooltip {
    background-color: AliceBlue;
}
</style>