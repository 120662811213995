import Vue from 'vue';

import numeral from 'numeral';

Vue.filter('round', (input, fractionDigits = 0) => {
    let number = parseFloat(input);
    if (isNaN(number)) {
        number = 0;
    }

    switch (fractionDigits) {
        case 1:
            return numeral(number).format('0,0.[0]').replace(', ', ',');
        case 2:
            return numeral(number).format('0,0.[00]').replace(', ', ',');
        default:
            return numeral(number).format('0,0').replace(', ', ',');
    }
});
