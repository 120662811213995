import Repository from './Repository.js';

class SalesRepository extends Repository {
    async getSales(query) {
        return await this.get('sales', query);
    }

    async getSale(saleId, query) {
        return await this.get(`sales/${saleId}`, query);
    }

    async createSale(body) {
        return await this.post('sales', body);
    }

    async updateSale(saleId, body) {
        return await this.post(`sales/${saleId}`, body);
    }

    async startSignatureProcess(saleId) {
        return await this.post(`sales/${saleId}/signatureProcess`);
    }

    async restartSignatureProcess(saleId) {
        return await this.post(`sales/${saleId}/restartSignatureProcess`);
    }
}

export default new SalesRepository();
