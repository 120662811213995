import Repository from './Repository.js';

class ProgramsRepository extends Repository {
    async getPrograms(query) {
        return await this.get('programs', query);
    }

    async getProgram(programId, query) {
        return await this.get(`programs/${programId}`, query);
    }
}

export default new ProgramsRepository();
