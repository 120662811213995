import Repository from './Repository.js';

class DocumentsRepository extends Repository {
    async uploadDocument(input) {
        const body = new FormData();

        for (const key in input) {
            const value = input[key];
            if (Array.isArray(value)) {
                for (const val of value) {
                    body.append(key, val);
                }
            } else {
                body.append(key, value);
            }
        }

        return await this.post('documents', body, false);
    }

    async deleteDocument(documentId, body) {
        return await this.post(`documents/${documentId}/delete`, body);
    }
}

export default new DocumentsRepository();
