import store from '../store';

export default class Repository {
    async get(path, query = {}, providerCode = true) {
        if (store.state.application.localEnv) {
            query.debug = 1;
        }
        const queryString = (new URLSearchParams(query)).toString();
        let url = `${store.state.application.apiRootUrl}/${path}`;
        if (queryString.length > 0) {
            url = `${url}?${queryString}`;
        }

        const headers = {};

        if (providerCode) {
            headers['x-provider-code'] = store.state.application.providerCode;
        }

        const response = await fetch(url, {
            headers,
            credentials: 'include'
        });

        if (response.status === 401) {
            store.commit('application/setSessionExpiredDialog', true);
        }

        return await response.json();
    }

    async post(path, body, json = true) {
        const query = {};
        if (store.state.application.localEnv) {
            query.debug = 1;
        }
        const queryString = (new URLSearchParams(query)).toString();
        let url = `${store.state.application.apiRootUrl}/${path}`;
        if (queryString.length > 0) {
            url = `${url}?${queryString}`;
        }

        const headers = {
            'x-provider-code': store.state.application.providerCode
        };

        if (json) {
            headers['Content-Type'] = 'application/json';
        }

        const response = await fetch(url, {
            method: 'POST',
            headers,
            credentials: 'include',
            body: json ? JSON.stringify(body) : body
        });

        if (response.status === 401) {
            store.commit('application/setSessionExpiredDialog', true);
        }

        return response.json();
    }

    async put(path, body) {
        const query = {};
        if (store.state.application.localEnv) {
            query.debug = 1;
        }
        const queryString = (new URLSearchParams(query)).toString();
        let url = `${store.state.application.apiRootUrl}/${path}`;
        if (queryString.length > 0) {
            url = `${url}?${queryString}`;
        }

        const headers = {
            'Content-Type': 'application/json',
            'x-provider-code': store.state.application.providerCode
        };

        const response = await fetch(url, {
            method: 'PUT',
            headers,
            credentials: 'include',
            body: JSON.stringify(body)
        });

        if (response.status === 401) {
            store.commit('application/setSessionExpiredDialog', true);
        }

        return response.json();
    }

    async delete(path) {
        const query = {};
        if (store.state.application.localEnv) {
            query.debug = 1;
        }
        const queryString = (new URLSearchParams(query)).toString();
        let url = `${store.state.application.apiRootUrl}/${path}`;
        if (queryString.length > 0) {
            url = `${url}?${queryString}`;
        }

        const headers = {
            'x-provider-code': store.state.application.providerCode
        };

        const response = await fetch(url, {
            method: 'DELETE',
            headers,
            credentials: 'include'
        });

        if (response.status === 401) {
            store.commit('application/setSessionExpiredDialog', true);
        }

        return response.json();
    }
}
