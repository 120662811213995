<template>
    <v-navigation-drawer v-model="isOpen" absolute temporary>
        <v-list v-if="$store.state.application.isConnected">
            <v-list-item to="/">
                <v-list-item-title class="text-none grey--text text--darken-1 mr-2">
                    Accueil
                </v-list-item-title>
            </v-list-item>

            <v-list-item :to="programsPath" v-if="getConfig('programs.allowed_user_types', []).includes($store.state.application.user.type)" text dark class="text-none grey--text text--darken-1">
                <v-list-item-title>
                    Programmes
                </v-list-item-title>
            </v-list-item>

            <v-list-item :to="productsPath" v-if="getConfig('products.allowed_user_types', []).includes($store.state.application.user.type)" text dark class="text-none grey--text text--darken-1">
                <v-list-item-title>
                    Lots
                </v-list-item-title>
            </v-list-item>

            <v-list-item :to="contactsPath" v-if="getConfig('contacts.allowed_user_types', []).includes($store.state.application.user.type)" text dark class="text-none grey--text text--darken-1">
                <v-list-item-title>
                    Contacts
                </v-list-item-title>
            </v-list-item>

            <v-list-item :to="optionsPath" v-if="getConfig('options.allowed_user_types', []).includes($store.state.application.user.type)" text dark class="text-none grey--text text--darken-1">
                <v-list-item-title>
                    Options
                </v-list-item-title>
            </v-list-item>

            <v-list-item :to="salesPath" v-if="getConfig('options.allowed_user_types', []).includes($store.state.application.user.type)" text dark class="text-none grey--text text--darken-1">
                <v-list-item-title>
                    Réservations
                </v-list-item-title>
            </v-list-item>

            <v-list-item to="/actualites" v-if="getConfig('actualities.allowed_user_types', []).includes($store.state.application.user.type)" text dark class="text-none grey--text text--darken-1">
                <v-list-item-title>
                    Actualités
                </v-list-item-title>
            </v-list-item>

            <v-list-item to="/messages" v-if="getConfig('messages.allowed_user_types', []).includes($store.state.application.user.type)" text dark class="text-none grey--text text--darken-1">
                <v-list-item-title>
                    Messages
                </v-list-item-title>
            </v-list-item>

            <!-- <v-list-item to="/etapes" v-if="getConfig('steps.allowed_user_types', []).includes($store.state.application.user.type)">
                <v-list-item-title class="text-none grey--text text--darken-1 mr-2">
                    Étapes
                </v-list-item-title>
            </v-list-item> -->
        </v-list>
    </v-navigation-drawer>
</template>
<script>
export default {
    name: 'NavigationDrawerMobile',

    data: () => ({
        displayDrawer: false
    }),

    computed: {
        isOpen: {
            get() {
                return this.$store.state.application.displayMobileDrawer;
            },

            set(value) {
                this.$store.commit('application/setDisplayMobileDrawer', value);
            }
        },

        programsPath() {
            let url = '/programmes';
            if (this.getConfig('programs.default_filters')) {
                url = `${url}?${this.getConfig('programs.default_filters')}`;
            }
            return url;
        },
        productsPath() {
            let url = '/lots';
            if (this.getConfig('products.default_filters')) {
                url = `${url}?${this.getConfig('products.default_filters')}`;
            }
            return url;
        },
        contactsPath() {
            let url = '/contacts';
            if (this.getConfig('contacts.default_filters')) {
                url = `${url}?${this.getConfig('contacts.default_filters')}`;
            }
            return url;
        },
        optionsPath() {
            let url = '/options';
            if (this.getConfig('options.default_filters')) {
                url = `${url}?${this.getConfig('options.default_filters')}`;
            }
            return url;
        },
        salesPath() {
            let url = '/reservations';
            if (this.getConfig('sales.default_filters')) {
                url = `${url}?${this.getConfig('sales.default_filters')}`;
            }
            return url;
        }
    }
};
</script>
