import Repository from './Repository.js';

class OptionsRepository extends Repository {
    async getOptions(query) {
        return await this.get('options', query);
    }

    async createOption(body) {
        return await this.post('options', body);
    }

    async confirmOption(optionId, body) {
        return await this.post(`options/${optionId}/confirm`, body);
    }

    async cancelOption(optionId, body) {
        return await this.post(`options/${optionId}/cancel`, body);
    }

    async convertToSale(optionId) {
        return await this.post(`options/${optionId}/convertToSale`);
    }
}

export default new OptionsRepository();
