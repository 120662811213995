<template>
    <v-app :style="cssVariables">
        <v-main>
            <AppBar v-if="!$vuetify.breakpoint.mobile" />
            <AppBarMobile v-if="$vuetify.breakpoint.mobile" />
            <NavigationDrawerMobile v-if="$vuetify.breakpoint.mobile" />

            <router-view />
        </v-main>

        <v-footer id="footer">
            <v-row no-gutters>
                <v-col v-if="$store.state.application.isConnected">
                    <a class="no-text-decoration black--text mr-4" :href="getConfig('app.website_url', '')" target="_blank">
                        <small> Site {{ getConfig('app.name', '') }} </small>
                    </a>

                    <router-link v-if="getConfig('app.legal_notice', '')" class="no-text-decoration black--text mr-4" to="/mentions-legales">
                        <small> Mentions légales </small>
                    </router-link>
                </v-col>

                <v-col class="text-right">
                    <small> v{{ $store.state.application.version }} - </small>
                    <small> DP2I © 2022 </small>
                </v-col>
            </v-row>
        </v-footer>

        <v-overlay :value="$store.state.application.loading" z-index="999">
            <v-progress-circular indeterminate size="64" />
        </v-overlay>

        <NotificationsHub />

        <SessionExpiredDialog />

        <DebugAccounts />
    </v-app>
</template>

<script>
import AppBar from './components/appbar/AppBar.vue';
import AppBarMobile from './components/appbar/AppBarMobile.vue';
import DebugAccounts from './components/debug/DebugAccounts.vue';
import NotificationsHub from './components/NotificationsHub.vue';
import SessionExpiredDialog from './components/errors/SessionExpiredDialog.vue';
import NavigationDrawerMobile from './components/appbar/NavigationDrawerMobile.vue';

export default {
    name: 'App',

    components: {
        AppBar,
        AppBarMobile,
        DebugAccounts,
        NotificationsHub,
        SessionExpiredDialog,
        NavigationDrawerMobile
    },

    computed: {
        cssVariables() {
            // from https://github.com/vuejs/vue/issues/7346
            const theme = this.$vuetify.theme.isDark ? this.$vuetify.theme.themes.dark : this.$vuetify.theme.themes.light;
            return {
                '--primary-color': theme.primary,
                '--success-color': theme.success,
                '--dashboard-contact': this.getConfig('theme.dashboard_contact', 'blue'),
                '--program-chip': this.getConfig('theme.program_chip', 'blue'),
                '--product-background': this.getConfig('theme.product_background', 'lighten-2'),
                '--program-chip-lighten': this.getConfig('theme.program_chip_lighten', 'blue lighten-2'),
                '--contact-background': this.getConfig('theme.contact_background', 'blue accent-3'),
                '--contact-foreground': this.getConfig('theme.contact_foreground', 'blue lighten-4')
            };
        }
    },

    watch: {
        '$store.state.application.config'(config) {
            this.$vuetify.theme.themes.light.primary = config.theme.primary;
        }
    }
};
</script>
