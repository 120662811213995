<template>
    <v-dialog v-model="isOpen" width="50%">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Calculette financière
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text class="pt-4">
                <v-row>
                    <v-col>
                        <v-sheet color="rounded grey lighten-4 pa-4">
                            <h6 class="subtitle-1 primary--text">
                                Calculer ses mensualités
                            </h6>

                            <!-- <a href="https://www.meilleurtaux.com/credit-immobilier/barometre-des-taux.html" target="_blank">
                                Voir les taux actuels
                            </a> -->

                            <ValidationObserver ref="mensualitiesObserver">
                                <ValidationProvider v-slot="{ errors }" name="Montant du capital emprunté" rules="required|number">
                                    <v-text-field v-model="mensualities.loan" label="Montant du capital emprunté" append-icon="fas fa-euro-sign" :error-messages="errors" outlined dense />
                                </ValidationProvider>
                                <ValidationProvider v-slot="{ errors }" name="Taux d'intérêt (annuel)" rules="required|number">
                                    <v-text-field v-model="mensualities.interest" label="Taux d'intérêt (annuel)" append-icon="fas fa-percent" :error-messages="errors" outlined dense />
                                </ValidationProvider>
                                <ValidationProvider v-slot="{ errors }" name="Durée en années" rules="required">
                                    <v-select v-model="mensualities.years" label="Durée en années" :items="years" :menu-props="{ bottom: true, offsetY: true }" :error-messages="errors" outlined dense />
                                </ValidationProvider>
                            </ValidationObserver>

                            <v-btn @click="computeMentualites()" color="primary" small> Calculer </v-btn>

                            <template v-if="displayMentualities">
                                <br>
                                <br>

                                Montant des mensualités: {{ mensualities.mensualitesAmount | toCurrencyString() }} <br>
                                Coût total capital + crédit immobilier: {{ mensualities.totalCapital | toCurrencyString() }} <br>
                                Coût total des intérêts sur le capital: {{ mensualities.totalInterets | toCurrencyString(true)  }}
                            </template>
                        </v-sheet>
                    </v-col>

                    <v-col>
                        <v-sheet color="rounded grey lighten-4 pa-4">
                            <h6 class="subtitle-1 primary--text">
                                Calculer sa capacité d'emprunt
                            </h6>

                            <!-- <a href="https://www.meilleurtaux.com/credit-immobilier/barometre-des-taux.html" target="_blank">
                                Voir les taux actuels
                            </a> -->

                            <ValidationObserver ref="loanObserver">
                                <ValidationProvider v-slot="{ errors }" name="Montant des mensualités" rules="required|number">
                                    <v-text-field v-model="loan.mensualities" label="Montant des mensualités" append-icon="fas fa-euro-sign" :error-messages="errors" outlined dense />
                                </ValidationProvider>
                                <ValidationProvider v-slot="{ errors }" name="Taux d'intérêt (annuel)" rules="required|number">
                                    <v-text-field v-model="loan.interest" label="Taux d'intérêt (annuel)" append-icon="fas fa-percent" :error-messages="errors" outlined dense />
                                </ValidationProvider>
                                <ValidationProvider v-slot="{ errors }" name="Durée en années" rules="required">
                                    <v-select v-model="loan.years" label="Durée en années" :items="years" :menu-props="{ bottom: true, offsetY: true }" :error-messages="errors" outlined dense />
                                </ValidationProvider>
                            </ValidationObserver>

                            <v-btn @click="computeLoan()" color="primary" small> Calculer </v-btn>

                            <template v-if="displayLoan">
                                <br>
                                <br>

                                Montant du capital empruntable: {{ this.loan.loanable | toCurrencyString() }} <br>
                                Coût total capital + crédit immobilier: {{ this.loan.totalCapital | toCurrencyString() }} <br>
                                Coût total des intérêts sur le capital: {{ this.loan.totalInterets | toCurrencyString() }}
                            </template>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer />

                <v-btn @click="isOpen = false" small>
                    Fermer
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'FinancingCalculator',

    data: () => ({
        isOpen: false,
        years: [
            { value: 6, text: '6 ans' },
            { value: 7, text: '7 ans' },
            { value: 10, text: '10 ans' },
            { value: 15, text: '15 ans' },
            { value: 20, text: '20 ans' },
            { value: 25, text: '25 ans' },
            { value: 30, text: '30 ans' }
        ],

        displayMentualities: false,
        mensualities: {
            loan: null,
            interest: null,
            years: null,

            mensualitesAmount: null,
            totalCapital: null,
            totalInterets: null
        },

        displayLoan: false,
        loan: {
            mensualities: null,
            interest: null,
            years: null,

            loanable: null,
            totalCapital: null,
            totalInterets: null
        }
    }),

    methods: {
        async computeMentualites() {
            const valid = await this.$refs.mensualitiesObserver.validate();
            if (!valid) {
                return;
            }
            const loan = this.mensualities.loan.replace(',', '.').replace(' ', '');
            const interest = this.mensualities.interest.replace(',', '.').replace(' ', '');
            this.mensualities.mensualitesAmount = ((loan * interest / 1200) / (1 - (Math.pow((1 + this.mensualities.interest / 1200), (-this.mensualities.years * 12)))));
            this.mensualities.totalCapital = this.mensualities.mensualitesAmount * this.mensualities.years * 12;
            this.mensualities.totalInterets = this.mensualities.totalCapital - loan;
            this.displayMentualities = true;
        },

        async computeLoan() {
            const valid = await this.$refs.loanObserver.validate();
            if (!valid) {
                return;
            }

            const mensualities = this.loan.mensualities.replace(',', '.').replace(' ', '');
            const intesrest = this.loan.interest.replace(',', '.').replace(' ', '');
            this.loan.loanable = mensualities * (Math.pow(((intesrest / 1200) / (1 - (Math.pow(1 + this.loan.interest / 1200, -this.loan.years * 12)))), -1));
            this.loan.totalCapital = mensualities * this.loan.years * 12;
            this.loan.totalInterets = this.loan.totalCapital - this.loan.loanable;
            this.displayLoan = true;
        },

        resetMentualities() {
            this.mensualities.loan = null;
            this.mensualities.interest = null;
            this.mensualities.years = null;
            this.mensualities.mensualitesAmount = null;
            this.mensualities.totalCapital = null;
            this.mensualities.totalInterets = null;
            this.displayMentualities = false;
        },

        resetLoan() {
            this.loan.mensualities = null;
            this.loan.interest = null;
            this.loan.years = null;
            this.loan.loanable = null;
            this.loan.totalCapital = null;
            this.loan.totalInterets = null;
            this.displayLoan = false;
        }
    },

    watch: {
        isOpen() {
            this.resetMentualities();
            this.resetLoan();
        }
    }
};
</script>
