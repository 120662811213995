import store from '../store';
import Repository from './Repository.js';

class AuthRepository extends Repository {
    async login(body) {
        return await this.post('auth/login', body);
    }

    async disconnect(body) {
        return await this.post('auth/disconnect', body);
    }
    
    async getMe(query = {}) {
        query.providerCode = store.state.application.providerCode;
        return await this.get('auth/me', query);
    }

    async updateMe(body) {
        return await this.post('auth/me', body);
    }

    async forgotPassword(body) {
        return await this.post('auth/forgot-password', body);
    }

    async resetPassword(body) {
        return await this.post('auth/reset-password', body);
    }
}

export default new AuthRepository();
