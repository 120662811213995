<template>
    <v-app-bar color="white" app dense elevate-on-scroll>
        <v-toolbar-items>
            <v-btn class="text-capitalize" :to="logoPath" text dark>
                <v-img class="ml-auto" :src="getConfig('app.logo_url', '')" max-width="200" contain height="50" />
            </v-btn>
        </v-toolbar-items>

        <v-row class="d-none d-xl-block">
            <v-col class="grey--text text--darken-1">
                <em>
                    {{ getConfig('app.catch_phrase', '') }}
                </em>
            </v-col>
        </v-row>

        <v-spacer />

        <div v-if="$store.state.application.isConnected">
            <v-btn class="text-capitalize grey--text text--darken-1 mr-2" v-if="getConfig('programs.allowed_user_types', []).includes($store.state.application.user.type)" :to="programsPath" text dark>
                Programmes
            </v-btn>

            <v-btn class="text-capitalize grey--text text--darken-1 mr-2" v-if="getConfig('products.allowed_user_types', []).includes($store.state.application.user.type)" :to="productsPath" text dark>
                Lots
            </v-btn>

            <v-btn class="text-capitalize grey--text text--darken-1 mr-2" v-if="getConfig('contacts.allowed_user_types', []).includes($store.state.application.user.type)" :to="contactsPath" text dark>
                Contacts
            </v-btn>

            <v-btn class="text-capitalize grey--text text--darken-1 mr-2" v-if="getConfig('options.allowed_user_types', []).includes($store.state.application.user.type)" :to="optionsPath" text dark>
                Options
            </v-btn>

            <v-btn class="text-capitalize grey--text text--darken-1 mr-2" v-if="getConfig('options.allowed_user_types', []).includes($store.state.application.user.type)" :to="salesPath" text dark>
                Réservations
            </v-btn>

            <v-btn class="text-capitalize grey--text text--darken-1 mr-2" v-if="getConfig('actualities.allowed_user_types', []).includes($store.state.application.user.type)" to="/actualites" text dark>
                Actualités
            </v-btn>

            <v-btn class="text-capitalize grey--text text--darken-1 mr-2" v-if="getConfig('messages.enabled', true) && getConfig('messages.allowed_user_types', []).includes($store.state.application.user.type)" to="/messages" text dark>
                Messages
            </v-btn>
        </div>

        <UserProfile v-if="$store.state.application.isConnected" />
    </v-app-bar>
</template>

<script>
import UserProfile from './UserProfile';

export default {
    name: 'AppBar',

    components: {
        UserProfile
    },

    data: () => ({
        dialog: false
    }),

    computed: {
        logoPath() {
            if (this.$store.state.application.user) {
                if (this.$store.state.application.user.type === 'partner') {
                    return '/';
                } else if (this.$store.state.application.user.type === 'user') {
                    if (this.getConfig('messages.enabled', true)) {
                        return '/messages';
                    } else {
                        return '/actualites';
                    }
                } else {
                    return '/';
                }
            }
            return '/';
        },

        programsPath() {
            let url = '/programmes';
            if (this.getConfig('programs.default_filters')) {
                url = `${url}?${this.getConfig('programs.default_filters')}`;
            }
            return url;
        },
        productsPath() {
            let url = '/lots';
            if (this.getConfig('products.default_filters')) {
                url = `${url}?${this.getConfig('products.default_filters')}`;
            }
            return url;
        },
        contactsPath() {
            let url = '/contacts';
            if (this.getConfig('contacts.default_filters')) {
                url = `${url}?${this.getConfig('contacts.default_filters')}`;
            }
            return url;
        },
        optionsPath() {
            let url = '/options';
            if (this.getConfig('options.default_filters')) {
                url = `${url}?${this.getConfig('options.default_filters')}`;
            }
            return url;
        },
        salesPath() {
            let url = '/reservations';
            if (this.getConfig('sales.default_filters')) {
                url = `${url}?${this.getConfig('sales.default_filters')}`;
            }
            return url;
        }
    }
};
</script>
