<template>
    <v-app-bar color="white" app dense elevate-on-scroll clipped-left>
        <v-app-bar-nav-icon @click="$store.commit('application/setDisplayMobileDrawer', true)"></v-app-bar-nav-icon>

        <v-spacer />

        <UserProfile v-if="$store.state.application.isConnected" />
    </v-app-bar>
</template>

<script>
import UserProfile from './UserProfile';

export default {
    name: 'AppBarMobile',

    data: () => ({
        displayDrawer: false
    }),

    components: {
        UserProfile
    }
};
</script>
