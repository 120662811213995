import { version } from '../../package.json';

export default {
    namespaced: true,

    state: {
        version,
        loading: false,
        localEnv: false,
        debug: false,
        providerCode: 'axessiadev',
        isConnected: false,
        sessionExpiredDialog: false,
        displayMobileDrawer: false,
        user: null,
        config: null,
        apiRootUrl: 'http://localhost:8081'
    },

    mutations: {
        init(state) {
            state.localEnv = location.hostname === 'localhost';

            if (state.localEnv) {
                const providerCode = localStorage.getItem('application/providerCode');
                if (providerCode !== null) {
                    state.providerCode = providerCode;
                }
                state.apiRootUrl = 'http://localhost:8081';
            } else {
                const [providerCode] = location.hostname.split('.');
                state.providerCode = providerCode;
                state.apiRootUrl = `${location.origin}/api`;
            }

            const debug = localStorage.getItem('application/debug');
            if (debug !== null) {
                state.debug = debug === '1';
            }
        },

        disconnect(state) {
            state.isConnected = false;
            state.user = null;
        },

        setLoading(state, loading) {
            state.loading = typeof loading === 'boolean' ? loading : false;
        },

        setDisplayMobileDrawer(state, display) {
            state.displayMobileDrawer = display;
        },

        setProviderCode(state, providerCode) {
            localStorage.setItem('application/providerCode', providerCode);
            state.providerCode = providerCode;
        },

        setConfig(state, config) {
            state.config = config;
        },

        setIsConnected(state, isConnected) {
            state.isConnected = isConnected;
        },

        setSessionExpiredDialog(state, sessionExpiredDialog) {
            state.sessionExpiredDialog = sessionExpiredDialog;
        },

        setUser(state, user) {
            state.user = user;
        }
    }
};