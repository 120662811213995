import Repository from './Repository.js';

class MessagingRepository extends Repository {
    async getConversations(query) {
        return await this.get('messaging', query);
    }

    async createConversation(body) {
        return await this.post('messaging', body);
    }

    async getMessages(groupId, query) {
        return await this.get(`messaging/${groupId}/messages`, query);
    }

    async createMessage(groupId, body) {
        return await this.post(`messaging/${groupId}/messages`, body);
    }

    async updateMessage(groupId, messageId, body) {
        return await this.put(`messaging/${groupId}/messages/${messageId}`, body);
    }

    async deleteMessage(groupId, messageId) {
        return await this.delete(`messaging/${groupId}/messages/${messageId}`);
    }
}

export default new MessagingRepository();
