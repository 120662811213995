import Repository from './Repository.js';

class ApplicationRepository extends Repository {
    async updateMe(body) {
        return await this.put('application/partners/me', body);
    }

    async getProducts(query) {
        return await this.get('application/partners/products', query);
    }
}

export default new ApplicationRepository();
