import Vue from 'vue';
import VueRouter from 'vue-router';

import authRepositry from './repositories/auth.js';
import store from './store';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: () => import(/* webpackChunkName: "Dashboard" */ './views/Dashboard.vue'),
            meta: { title: 'Dashboard', authRequired: true }
        },
        {
            path: '/connexion',
            component: () => import(/* webpackChunkName: "Login" */ './views/Login.vue'),
            meta: { title: 'Connexion', authRequired: false }
        },
        {
            path: '/connexion/utilisateur',
            component: () => import(/* webpackChunkName: "UserLogin" */ './views/UserLogin.vue'),
            meta: { title: 'Connexion utilisateur', authRequired: false }
        },
        {
            path: '/programmes',
            component: () => import(/* webpackChunkName: "Programs" */ './views/Programs.vue'),
            meta: { title: 'Programmes', authRequired: true }
        },
        {
            path: '/programmes/:programId',
            component: () => import(/* webpackChunkName: "Program" */ './views/Program.vue'),
            meta: { title: 'Programme', authRequired: true }
        },
        {
            path: '/lots',
            component: () => import(/* webpackChunkName: "Products" */ './views/Products.vue'),
            meta: { title: 'Lots', authRequired: true }
        },
        {
            path: '/contacts',
            component: () => import(/* webpackChunkName: "Contacts" */ './views/Contacts.vue'),
            meta: { title: 'Contacts', authRequired: true }
        },
        {
            path: '/contacts/:contactId',
            component: () => import(/* webpackChunkName: "Contact" */ './views/Contact.vue'),
            meta: { title: 'Contact', authRequired: true }
        },
        {
            path: '/options',
            component: () => import(/* webpackChunkName: "Options" */ './views/Options.vue'),
            meta: { title: 'Options', authRequired: true }
        },
        {
            path: '/reservations',
            component: () => import(/* webpackChunkName: "Sales" */ './views/Sales.vue'),
            meta: { title: 'Réservations', authRequired: true }
        },
        {
            path: '/reservations/:saleId',
            component: () => import(/* webpackChunkName: "Sale" */ './views/Sale.vue'),
            meta: { title: 'Réservation', authRequired: true }
        },
        {
            path: '/messages',
            component: () => import(/* webpackChunkName: "Messages" */ './views/Messages.vue'),
            meta: { title: 'Messages', authRequired: true }
        },
        {
            path: '/mon-compte',
            component: () => import(/* webpackChunkName: "MyAccount" */ './views/MyAccount.vue'),
            meta: { title: 'Mon compte', authRequired: true }
        },
        {
            path: '/historique',
            component: () => import(/* webpackChunkName: "History" */ './views/History.vue'),
            meta: { title: 'Historique', authRequired: true }
        },
        {
            path: '/actualites',
            component: () => import(/* webpackChunkName: "Actualities" */ './views/Actualities.vue'),
            meta: { title: 'Actualités', authRequired: true }
        },
        {
            path: '/parametres',
            component: () => import(/* webpackChunkName: "Settings" */ './views/Settings.vue'),
            meta: { title: 'Paramètres', authRequired: true }
        },
        {
            path: '/mentions-legales',
            component: () => import(/* webpackChunkName: "LegalNotice" */ './views/LegalNotice.vue'),
            meta: { title: 'Mentions légales', authRequired: true }
        },
        {
            path: '/reinitialisation-mot-de-passe',
            component: () => import(/* webpackChunkName: "ResetPassword" */ './views/ResetPassword.vue'),
            meta: { title: 'Réinitialisation de mot de passe', authRequired: false }
        },
        {
            path: '*',
            component: () => import(/* webpackChunkName: "NotFound" */ './views/NotFound.vue'),
            redirect: '/'
        }
    ]
});

router.beforeEach(async function (to, from, next) {
    document.title = `Extranet - ${to.meta.title}`;
    try {
        const { isConnected, user, config } = await authRepositry.getMe();
        store.commit('application/setIsConnected', isConnected);
        store.commit('application/setConfig', config);
        if (to.meta.authRequired) {
            if (isConnected) {
                store.commit('application/setUser', user);
                next();
            } else {
                store.commit('application/setSessionExpiredDialog', false);
                let connexionPath = '/connexion';
                if (to.query.t === 'u') {
                    connexionPath = '/connexion/utilisateur';
                }
                if (to.path) {
                    next(connexionPath);
                } else {
                    next(`${connexionPath}?redirect=${to.path}&hash=${to.hash.slice(1)}`);
                }
            }
        } else {
            next();
        }
    } catch (err) {
        console.error(err);
        Vue.notify({
            type: 'error',
            title: 'Erreur',
            text: 'Une erreur est survenue durant le chargement de la page',
            duration: 10000
        });
    }
});

export default router;
